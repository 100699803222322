import { useCallback, useEffect, useMemo, useState } from 'react';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { defaultAvatar } from 'src/constants/contact';
import API from "src/services/Api";
import uupStorage from 'src/contexts/DB';
interface Props {
  url: string | null;
  onClick?: () => void;
  className?: string;
  alt: string;
  fallback?: string;
  fallbackElement?: JSX.Element;
  spinnerSize?: string;
  onLoad?: () => void;
  skipLoading?: boolean;
}
function LoadingImage({
  url,
  onClick,
  className,
  alt,
  fallback: fallbackUrl,
  fallbackElement,
  spinnerSize,
  onLoad,
  skipLoading,
  ...rest
}: Props): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true);
  const [fallbackString, $fallbackString] = useState<string>();
  const [fallbackJSX, $fallbackJSX] = useState<JSX.Element>();
  const [avatarUrl, $avatarUrl] = useState<string | null>();
  useEffect(() => {
    $fallbackString(undefined);
    $fallbackJSX(undefined);
  }, [url]);
  const getAvatarUrl = useCallback(async (url: string) => {
    setLoading(true);
    $avatarUrl(null);
    const splittedUrl = (url ?? '').split('/media')[1];
    if (splittedUrl?.includes('profile-photos')) {
      const cachedImage = (await uupStorage.getItem(`attachment-${url}`) as Blob);
      if (cachedImage instanceof Blob) {
        $avatarUrl(URL.createObjectURL(cachedImage));
      } else {
        const response = await API.get<File>(`/files/media${encodeURIComponent(splittedUrl)}`, {
          responseType: 'blob'
        });
        $avatarUrl(URL.createObjectURL(response.data));
        await uupStorage.setItem(`attachment-${url}`, response.data);
      }
      setLoading(false);
      return;
    }
    const img = new Image();
    img.src = url ?? fallbackUrl ?? '';
    img.onload = () => {
      setLoading(false);
    };
    img.onerror = () => {
      setLoading(false);
      if (fallbackUrl !== undefined) $fallbackString(fallbackUrl);else if (fallbackElement !== undefined) $fallbackJSX(fallbackElement);
    };
  }, []);
  useEffect(() => {
    getAvatarUrl(url ?? '').catch(console.error);
  }, [url]);
  const renderImage = useMemo(() => {
    if (fallbackJSX !== undefined) return fallbackJSX;
    return <img src={fallbackString ?? avatarUrl ?? url ?? defaultAvatar} onClick={onClick} className={className} alt={alt} {...onLoad != null ? {
      onLoad: () => {
        onLoad();
      }
    } : {}} {...rest} />;
  }, [alt, className, fallbackJSX, fallbackString, avatarUrl, onClick, onLoad, rest, url]);
  return skipLoading === true ? renderImage : loading ? <LoadingSpinner size={spinnerSize} /> : renderImage;
}
export default LoadingImage;