import { useMemo, useCallback, useState } from 'react';
import { parseTimestamp, saveFileToDisk } from 'src/helpers/message';
import { type DecryptedMessage, type VideoAttachment } from 'src/types/Message';
import { formatDuration } from 'src/helpers/common';
import moment from 'moment';
import LoadingImage from 'src/components/LoadingImage';
import uupStorage from 'src/contexts/DB';
import { getAWSSignedUrl } from 'src/helpers/file';
import axios from 'axios';
import MessageDeliveryTicks from 'src/components/UI/MessageDeliveryTicks';
import { EventBus } from 'src/services/EventBus';
import { UupEvents } from 'src/constants/events';
import clsx from 'clsx';
import { useAppDispatch, useContacts } from 'src/hooks/store';
import { showGallery } from 'src/store/slices/contacts';
import { MessageType } from 'src/types/Ejabberd/MessageType';
interface Props {
  message: DecryptedMessage;
  isGalleryItem?: boolean;
  isMainGalleryItem?: boolean;
}
interface DownloadedData {
  url: string;
  blob: Blob;
}
function VideoMessage({
  message,
  isGalleryItem = false,
  isMainGalleryItem = false
}: Props): JSX.Element | null {
  const {
    timestamp,
    received,
    abortControllerId,
    isDelivered
  } = message;
  const attachment = (message.attachment as VideoAttachment);
  const dispatch = useAppDispatch();
  const {
    activeContact,
    activeGroup,
    messages
  } = useContacts();
  const {
    isUploaded
  } = message;
  const [downloadedData, $downloadedData] = useState<DownloadedData | null>(null);
  const formattedTimestamp: string = moment.unix(parseTimestamp(timestamp)).format('HH:mm');
  const [fullscreen, $fullscreen] = useState<boolean>(false);
  const [error, $error] = useState<boolean>(false);
  const [progress, $progress] = useState<number | null>(null);
  const fullPath = `media/videos/${attachment.data}`;
  const getStoredFile = useCallback(async (): Promise<DownloadedData | null> => {
    try {
      const attachmentId = message.oldId ?? message.id;
      const blob = (await uupStorage.getItem(`attachment-${attachmentId}`) as Blob);
      if (blob === null) return null;
      const url = URL.createObjectURL(blob);
      return {
        url,
        blob
      };
    } catch (error) {
      console.error('[VIDEO MESSAGE] Get stored file', error);
      return null;
    }
  }, [message.id, message.oldId]);
  const downloadWithSignedURL = useCallback(async () => {
    try {
      console.log('[VOICE MESSAGE] Start download');
      $progress(0);
      const signedData = await getAWSSignedUrl(fullPath, 'GET_OBJECT');
      if (signedData === false) return;
      const download = await axios.get(signedData.url, {
        responseType: 'blob',
        onDownloadProgress: progressEvent => {
          if (progressEvent.lengthComputable === false) return;

          // const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
          // const current = progressEvent.currentTarget.response.length
          const total = parseFloat(progressEvent.total);
          const current = progressEvent.loaded;
          const percentCompleted = Math.round(current / total * 100);
          $progress(percentCompleted);
        }
      });
      const blob = new Blob([download.data], {
        type: 'video/mp4'
      });
      const url = URL.createObjectURL(blob);
      saveFileToDisk(message.id, blob).catch(console.error);
      $downloadedData({
        url,
        blob
      });
    } catch (error) {
      console.error('[VOICE MESSAGE] Start download', error);
      $error(true);
    } finally {
      $progress(null);
    }
  }, [fullPath, message]);
  const handleEntry = useCallback(async (entry: IntersectionObserverEntry) => {
    if (!entry.isIntersecting) return;
    // if (!received && isUploaded !== true) return
    const storedFile = await getStoredFile();
    if (storedFile !== null) {
      $downloadedData(storedFile);
      return;
    }
    downloadWithSignedURL().catch(console.error);
  }, [getStoredFile, downloadWithSignedURL]);
  const intersectionCallback = useCallback((entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      void handleEntry(entry);
    });
  }, [handleEntry]);
  const download = useCallback(() => {
    window.open(downloadedData?.url, '_blank');
  }, [downloadedData?.url]);
  const toggleContactMediaViewer = useCallback(() => {
    if (activeContact === null) return;
    const id = Strophe.getNodeFromJid(activeContact.jid);
    const galleryItems = messages[id].filter(message => [MessageType.IMAGE, MessageType.VIDEO].includes(message.type)).reverse();
    dispatch(showGallery({
      galleryItems,
      mainGalleryItem: message
    }));
    $fullscreen(true);
  }, [activeContact, dispatch, message, messages]);
  const toggleGroupMediaViewer = useCallback(() => {
    if (activeGroup === null) return;
    const id = Strophe.getNodeFromJid(activeGroup.jid);
    const galleryItems = messages[id].filter(message => [MessageType.IMAGE, MessageType.VIDEO].includes(message.type)).reverse();
    dispatch(showGallery({
      galleryItems,
      mainGalleryItem: message
    }));
    $fullscreen(true);
  }, [activeGroup, messages, dispatch, message]);
  const toggleFullscreen = useCallback(() => {
    $fullscreen(prev => !prev);
  }, []);
  const renderFullScreen = useMemo(() => <div className={clsx(isMainGalleryItem ? 'w-full h-full' : isGalleryItem ? 'w-32 h-32' : 'w-52 h-64 center-flex')}>
            <div className="relative w-full h-full center-flex">
                {isMainGalleryItem ? <>
                        <video src={downloadedData?.url} className="w-full h-[80%]" controls={true} />
                        <div className="flex items-center absolute top-2 right-4">

                            <button onClick={download} className='group hover:bg-primary w-8 h-8 mr-2 bg-gray-100 shadow rounded-sm transition hover:opacity-80'>
                                <i className="bi bi-download text-primary-600 group-hover:text-white"></i>
                            </button>
                            <button onClick={toggleFullscreen} className='group hover:bg-red-600 w-8 h-8 flex justify-center items-center bg-gray-100 shadow rounded-sm transition hover:opacity-80'>
                                <i className="bi bi-x text-xl text-red-600 group-hover:text-white"></i>
                            </button>
                        </div>
                    </> : <LoadingImage url={`data:image/png;base64,${attachment.thumbnail}`} className={clsx('w-full h-full rounded-sm mb-0 transition hover:opacity-80 cursor-pointer', isMainGalleryItem ? 'object-contain' : 'object-cover')} alt="thumbnail" />}
            </div>
        </div>, [attachment.thumbnail, download, downloadedData?.url, isGalleryItem, isMainGalleryItem, toggleFullscreen]);
  const initRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const observer = new IntersectionObserver(intersectionCallback, {
        root: null,
        rootMargin: "0px",
        threshold: 0.4
      });
      observer.observe(node);
    }
  }, [intersectionCallback]);
  const cancelUpload = useCallback(() => {
    if (abortControllerId === null) return;
    EventBus.next({
      type: UupEvents.CANCEL_UPLOAD,
      payload: {
        abortControllerId,
        messageId: message.id
      }
    });
  }, [abortControllerId, message.id]);
  const onPressVideo = useCallback(() => {
    if (activeContact !== null) toggleContactMediaViewer();else if (activeGroup !== null) toggleGroupMediaViewer();
  }, [activeContact, activeGroup, toggleContactMediaViewer, toggleGroupMediaViewer]);
  const renderMessage = useMemo(() => {
    const hasOngoingExchange = progress !== null || !received && isUploaded !== true;
    const isDownloaded = downloadedData !== null;
    const path = `data:image/png;base64,${attachment.thumbnail}`;
    const isUploading = abortControllerId !== null && abortControllerId !== undefined && isDelivered !== true;
    return <div ref={initRef} className={clsx('group shrink-0 relative flex flex-col items-center text-base', isMainGalleryItem && 'h-full', isGalleryItem && 'rounded overflow-hidden mx-2')}>

            {isUploading && <div onClick={cancelUpload} className="absolute top-0 z-50 left-0 w-full h-full center-flex flex-col gap-y-2 cursor-pointer transition hover:scale-[1.1]">
                    <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center">
                        <i className="bi bi-x text-xl text-red-600"></i>
                    </div>
                </div>}

            <div className={clsx(isMainGalleryItem ? 'w-3/4 h-full' : isGalleryItem ? 'w-32 h-32' : 'w-52 h-64 center-flex')}>
                {!isUploading && isDownloaded ? <video src={downloadedData?.url} className="w-full h-full object-contain" controls={!!isMainGalleryItem} autoPlay={isMainGalleryItem} /> : <LoadingImage url={path} className={clsx('w-full h-full rounded-sm mb-0 transition hover:opacity-80 cursor-pointer object-contain')} alt="thumbnail" />}
            </div>

            {!isMainGalleryItem && <div className="absolute z-40 transition top-0 left-0 w-full h-full center-flex bg-accent opacity-10 group-hover:opacity-40"></div>}

            {!isMainGalleryItem && !hasOngoingExchange && <button onClick={onPressVideo} className="absolute z-40 transition top-0 left-0 w-full h-full center-flex">
                <svg className='w-8 h-8' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 4.98951C5 4.01835 5 3.53277 5.20249 3.2651C5.37889 3.03191 5.64852 2.88761 5.9404 2.87018C6.27544 2.85017 6.67946 3.11953 7.48752 3.65823L18.0031 10.6686C18.6708 11.1137 19.0046 11.3363 19.1209 11.6168C19.2227 11.8621 19.2227 12.1377 19.1209 12.383C19.0046 12.6635 18.6708 12.886 18.0031 13.3312L7.48752 20.3415C6.67946 20.8802 6.27544 21.1496 5.9404 21.1296C5.64852 21.1122 5.37889 20.9679 5.20249 20.7347C5 20.467 5 19.9814 5 19.0103V4.98951Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>}

            <div className='absolute attachment-overlay px-2 py-2 w-full bottom-0 left-0 z-50 flex items-center justify-between'>
                <div className="flex items-center">
                    <svg className='w-4 h-4' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.15 6.17C20.74 5.95 19.88 5.72 18.71 6.54L17.24 7.58C17.13 4.47 15.78 3.25 12.5 3.25H6.5C3.08 3.25 1.75 4.58 1.75 8V16C1.75 18.3 3 20.75 6.5 20.75H12.5C15.78 20.75 17.13 19.53 17.24 16.42L18.71 17.46C19.33 17.9 19.87 18.04 20.3 18.04C20.67 18.04 20.96 17.93 21.15 17.83C21.56 17.62 22.25 17.05 22.25 15.62V8.38C22.25 6.95 21.56 6.38 21.15 6.17ZM11 11.38C9.97 11.38 9.12 10.54 9.12 9.5C9.12 8.46 9.97 7.62 11 7.62C12.03 7.62 12.88 8.46 12.88 9.5C12.88 10.54 12.03 11.38 11 11.38Z" fill="#fff" />
                    </svg>
                    <span className='text-accent text-2xs ml-2'>{formatDuration(parseInt(attachment.metaData.duration))}</span>
                </div>
                {isUploading && message.uploadingProgress !== undefined && <span className='text-white text-xs'>{message.uploadingProgress}%</span>}
                {!isGalleryItem && <div className="flex items-center">
                    <span className='text-accent text-xs'>{formattedTimestamp}</span>
                    <MessageDeliveryTicks message={message} />
                </div>}
            </div>

            {!received && isUploaded !== true && <div className='absolute w-full h-full flex justify-center items-center px-2 download-overlay pointer-events-none'>
                    <svg fill="#000" className='animate animate-spin w-12 h-12 z-50' viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 1.25c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0c7.318 0.001 13.25 5.933 13.25 13.251 0 3.659-1.483 6.972-3.881 9.37v0c-0.14 0.136-0.227 0.327-0.227 0.537 0 0.414 0.336 0.75 0.75 0.75 0.212 0 0.403-0.088 0.539-0.228l0-0c2.668-2.669 4.318-6.356 4.318-10.428 0-8.146-6.604-14.751-14.75-14.751h-0z"></path>
                    </svg>
                </div>}
            {progress !== null && <div className='absolute w-full h-full flex justify-center items-center px-2 download-overlay'>
                    <span className='text-xs text-accent mr-1'>{progress}%</span>
                    <svg fill="#fff" className='animate animate-spin w-4 h-4' viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 1.25c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0c7.318 0.001 13.25 5.933 13.25 13.251 0 3.659-1.483 6.972-3.881 9.37v0c-0.14 0.136-0.227 0.327-0.227 0.537 0 0.414 0.336 0.75 0.75 0.75 0.212 0 0.403-0.088 0.539-0.228l0-0c2.668-2.669 4.318-6.356 4.318-10.428 0-8.146-6.604-14.751-14.75-14.751h-0z"></path>
                    </svg>
                </div>}
        </div>;
  }, [progress, received, isUploaded, downloadedData, attachment.thumbnail, attachment.metaData.duration, initRef, abortControllerId, isDelivered, cancelUpload, isMainGalleryItem, isGalleryItem, onPressVideo, formattedTimestamp, message]);
  const renderError = useMemo(() => <div className='shadow flex items-center ml-auto my-1 w-fit rounded-sm pl-2 pr-1 py-2 bg-gray-200'>
            <span className='text-base text-gray-600'>Invalid Video</span>
            <span className='ml-2 relative -bottom-1 self-end text-gray-400 text-3xs'>{formattedTimestamp}</span>
        </div>, [formattedTimestamp]);
  return renderMessage;
}
export default VideoMessage;