import { type MessageType } from 'src/types/Ejabberd/MessageType';
import { type Contact } from 'src/types/Contact';

export interface MessageParameters {
    oldId?: string;
    mamId?: string | null;
    props?: any;
    isDeleted?: boolean;
    isReceived?: boolean;
    isRead?: boolean;
    isDelivered?: boolean;
    isUploaded?: boolean;
    abortControllerId?: string;
    thid?: string | null;
    uploadingProgress?: number;
}

export type MessageAttachment = VoiceAttachment | FileAttachment | VideoAttachment | LocationShareAttachment | ImageAttachment | GIFAttachment | ContactAttachment | string;

export interface DecryptedMessage extends MessageParameters {
    type: MessageType;
    id: string;
    quotedMessageId?: string;
    updatedAt?: number;
    fromId: string;
    toId: string;
    content?: string;
    attachment?: MessageAttachment;
    timestamp: number;
    received: boolean;
    additional?: any;
}

export interface LocationShareAttachment {
    latitude: string;
    longitude: string;
    name: string;
    thumbnail: string;
    myData?: string;
}

export interface ImageAttachment {
    metaData: {
        width?: string;
        height?: string;
        size: string;
    },
    data: string;
    thumbnail: string;
    myData?: string;
}

export interface GIFAttachment {
    metaData: {
        width?: string;
        height?: string;
        size: string;
    },
    data: string;
    myData?: string;
}

export interface ContactAttachment {
    numbers: string;
    name: string;
}

export interface VoiceAttachment {
    metaData: {
        duration: string;
        size: string;
    },
    data: string;
    myData?: string;
}

export interface VideoAttachment {
    metaData: {
        duration: string;
        size: string;
    },
    data: string;
    thumbnail: string;
    myData?: string;
}

export interface FileAttachment {
    metaData: {
        name: string;
        size: string;
    },
    data: string;
    myData?: string;
}

export interface DeleteMessageEventData {
    jid: string;
    messageId: string;
    hide?: boolean;
}

export enum OutgoingMessageType {
    TEXT = 'text',
    IMAGE = 'image',
    VIDEO = 'video',
    AUDIO = 'audio',
}


export interface ForwardingMessage<T, N> {
    message: DecryptedMessage | undefined,
    from: (N extends undefined ? Contact : N) | null,
    contacts: T[]
}

export enum MessageDeliveryEventType {
    DELIVERED = 'delivered',
    RECEIVED = 'received',
    READ = 'read',
}

export interface MessageDeliveryEventData {
    contactId: string;
    messageId?: string;
    type: `${MessageDeliveryEventType}`,
    isReceivedMessage?: boolean;
}

export interface MessageAdditionalData {
    qid?: string;
    thid?: string;
    filename?: string;
    mid?: string;
    ut?: number;
}
