import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { parseTimestamp, saveFileToDisk } from 'src/helpers/message';
import { type DecryptedMessage, type VoiceAttachment } from 'src/types/Message';
import WaveSurfer from 'wavesurfer.js';
import moment from 'moment';
import uupStorage from 'src/contexts/DB';
import { useActiveMedia } from 'src/contexts/ActiveMedia';
import { getAWSSignedUrl } from 'src/helpers/file';
import axios from 'axios';
import MessageDeliveryTicks from 'src/components/UI/MessageDeliveryTicks';
interface Props {
  message: DecryptedMessage;
}
interface DownloadedData {
  url: string;
  blob: Blob;
}
function VoiceMessage({
  message
}: Props): JSX.Element | null {
  const {
    activeMedia,
    setActiveMedia
  } = useActiveMedia();
  const {
    timestamp
  } = message;
  const attachment = (message.attachment as VoiceAttachment);
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const [playing, $playing] = useState<boolean>(false);
  const [progress, $progress] = useState<number | null>(null);
  const [currentProgress, $currentProgress] = useState<string>();
  const [downloadedData, $downloadedData] = useState<DownloadedData | null>(null);
  const [error, $error] = useState<boolean>(false);
  const fullPath = `media/voices/${attachment.data}`;
  const secondsToMMSS = (seconds: number): string => {
    const duration = moment.duration(seconds, 'seconds');
    const minutes = Math.floor(duration.asMinutes());
    const remainingSeconds = Math.floor(duration.asSeconds()) % 60;
    return moment({
      minutes,
      seconds: remainingSeconds
    }).format('mm:ss');
  };
  const getStoredFile = useCallback(async (): Promise<DownloadedData | null> => {
    try {
      console.log('[VOICE MESSAGE] Get stored file');
      const attachmentId = message.oldId ?? message.id;
      const blob = (await uupStorage.getItem(`attachment-${attachmentId}`) as Blob);
      if (blob === null) return null;
      const url = URL.createObjectURL(blob);
      return {
        url,
        blob
      };
    } catch (error) {
      console.error('[VOICE MESSAGE] Get stored file', error);
      return null;
    }
  }, [message.id, message.oldId]);
  const downloadWithSignedURL = useCallback(async () => {
    try {
      console.log('[VOICE MESSAGE] Start download');
      $progress(0);
      const signedData = await getAWSSignedUrl(fullPath, 'GET_OBJECT');
      if (signedData === false) return;
      const download = await axios.get(signedData.url, {
        responseType: 'blob',
        onDownloadProgress: progressEvent => {
          // const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
          // const current = progressEvent.currentTarget.response.length
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          const percentCompleted = Math.round(current / total * 100);
          $progress(percentCompleted);
        }
      });
      const blob = new Blob([download.data], {
        type: 'audio/mp3'
      });
      const url = URL.createObjectURL(blob);
      saveFileToDisk(message.id, blob).catch(console.error);
      $downloadedData({
        url,
        blob
      });
      if (wavesurfer.current !== null) {
        wavesurfer.current.loadBlob(blob);
      }
    } catch (error) {
      console.error('[VOICE MESSAGE] Start download', error);
      $error(true);
    } finally {
      $progress(null);
    }
  }, [fullPath, message.id]);
  const initialize = useCallback(async () => {
    const storedFile = await getStoredFile();
    if (storedFile === null) await downloadWithSignedURL();else {
      $downloadedData(storedFile);
      if (wavesurfer.current !== null) {
        wavesurfer.current.loadBlob(storedFile.blob);
      }
    }
  }, [getStoredFile, downloadWithSignedURL]);
  useEffect(() => {
    initialize().catch(console.error);
  }, [initialize]);
  const formattedTimestamp: string = moment.unix(parseTimestamp(timestamp)).format('HH:mm');
  useLayoutEffect(() => {
    if (wavesurfer.current !== null) return;
    const instance = WaveSurfer.create({
      container: `#waveform-${message.id}`,
      height: 40,
      waveColor: 'rgba(188, 197, 219, 0.5)',
      progressColor: 'rgba(188, 197, 219, 1)',
      cursorWidth: 0,
      cursorColor: 'rgba(188, 197, 219, 0.8)',
      responsive: true,
      barWidth: 2,
      barGap: 1,
      barMinHeight: 2,
      barRadius: 4,
      normalize: true
    });
    const duration = secondsToMMSS(Number(attachment.metaData.duration));
    instance.on('finish', () => {
      $playing(false);
      $currentProgress(duration);
    });
    instance.on('play', () => {
      $playing(true);
    });
    instance.on('pause', () => {
      $playing(false);
    });
    instance.on('audioprocess', () => {
      const time = secondsToMMSS(instance.getCurrentTime());
      $currentProgress(time);
    });
    wavesurfer.current = instance;
    $currentProgress(duration);
  }, [attachment.metaData.duration, message.id]);
  const play = async (): Promise<void> => {
    if (wavesurfer.current === null) return;
    if (activeMedia !== null && activeMedia !== wavesurfer.current) {
      activeMedia.pause();
      setActiveMedia(null);
    }
    await wavesurfer.current.play();
    setActiveMedia(wavesurfer.current);
  };
  const pause = (): void => {
    if (wavesurfer.current === null) return;
    wavesurfer.current.pause();
    setActiveMedia(null);
  };
  const isUploading = message.uploadingProgress !== undefined && message.uploadingProgress !== 100;
  return <div className='relative flex items-center ml-auto h-fit text-sm px-2 w-fit [content-visibility: auto]'>
            {/* {isUploading && (
                <div className="absolute top-0 z-50 bg-red-600 w-40 h-40 left-0 center-flex flex-col gap-y-2 cursor-pointer transition hover:scale-[1.1]">
                    <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center">
                        <i className="bi bi-x text-xl text-red-600"></i>
                    </div>
                   
                </div>
             )} */}
            
            {progress !== null && <div className='absolute w-full flex items-center px-2 items-center justify-center download-overlay'>
                    <span className='text-3xs text-accent mr-1'>{progress}%</span>
                    <svg fill="#000000" className='animate animate-spin w-3 h-3' viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 1.25c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0c7.318 0.001 13.25 5.933 13.25 13.251 0 3.659-1.483 6.972-3.881 9.37v0c-0.14 0.136-0.227 0.327-0.227 0.537 0 0.414 0.336 0.75 0.75 0.75 0.212 0 0.403-0.088 0.539-0.228l0-0c2.668-2.669 4.318-6.356 4.318-10.428 0-8.146-6.604-14.751-14.75-14.751h-0z"></path>
                    </svg>
                </div>}

            {playing ? <button onClick={pause} className='center-flex bg-light-blue rounded-md w-8 h-8 rounded text-white hover:bg-light-blue hover:text-white'>
                    <i className="bi bi-pause-fill text-xl"></i>
                </button> : <button onClick={play} className='center-flex  bg-light-blue rounded-md w-8 h-8 rounded text-white hover:bg-light-blue hover:text-white'>
                    <i className="bi bi-play-fill text-xl"></i>
                </button>}
            <div className='relative flex items-center'>
                <div id={`waveform-${message.id}`} className={`pt-2 pb-2 ml-2 mr-4 w-64 h-16`}></div>
                <div className='absolute bottom-0 left-0 flex space-between w-full'>
                    {isUploading && <span className='grow text-gray-400 text-2xs pl-2'>{message.uploadingProgress}%</span>}
                    <span className='grow text-gray-400 text-2xs pl-2'>{currentProgress}</span>
                    <span className='text-gray-400 text-2xs pr-2'>{formattedTimestamp}</span>
                </div>
            </div>
            <MessageDeliveryTicks message={message} />
            {error && <span className='text-base text-gray-600'>Invalid Voice Message</span>}
        </div>;
}
export default VoiceMessage;