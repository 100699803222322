import { chatSettingTypes } from "src/constants/contact";
import API from "src/services/Api";
import { type XMPPService } from "src/services/Ejabberd/XMPP";
import { type AppDispatch } from "src/store";
import { addContact, type ContactsState, type MessagesState, setContacts, sortContacts, setContactData, addGroup, fetchContactMessages, setRosterState, resetPendingRosters } from "src/store/slices/contacts";
import { type ChatSettings, type ChatSettingType, type ContactMessageData, type ParsedChatSettings, type Contact, type VirtualCard, ContactTypes } from "src/types/Contact";
import { type DecryptedMessage } from "src/types/Message";
import { type EncryptedMessage } from "src/types/Ejabberd/Message";
import { xmlToJson } from "./xmpp";
import { fetchGroupDetails, fetchGroupMembers, fetchGroupMeta, isGroup } from "./group";
import { type User } from "src/types/User";
import { setUserData } from "src/store/slices/user";
import { type CommonSearchResult, type CommonSearchResultUser, type SearchResultUser } from "src/types/Search";
import { HOST, xmpp } from "src/constants/xmpp";
import { type AxiosResponse } from "axios";
import { type UserDataResponse } from "src/types/API/User";
import { RosterStateType } from "src/types/Roster";
import { updateObjects } from "./common";
import { generateTimestamp } from "./message";
import { logoutApp } from 'src/helpers/app';
import { type GroupUser } from "src/types/Group";
import { type StorageRecordResponse } from "src/types/API/StorageRecord";

interface ContactRecord {
    to: string;
    data: Record<string, number>;
}

let timer: NodeJS.Timeout | null = null;
let records: ContactRecord[] = [];

async function updateContactsRecord(updatedContacts: Contact[]): Promise<Contact[]> {
    const response = await API.get("/storage/records")
    const records: StorageRecordResponse[] = response.data
    updatedContacts.forEach(contact => {
        const record = records.find(record => record.to === getNodeFromJid(contact.jid))
        if (record?.data.clearedAt !== undefined) {
            contact.isClearedAt = Number(`${record.data.clearedAt}`)
        }
        if (record?.data.readAt !== undefined) {
            contact.isReadAt = Number(`${record.data.readAt}`)
        }
        if (record?.data.receivedAt !== undefined) {
            contact.isReceivedAt = Number(`${record.data.receivedAt}`)
        }
    });

    return updatedContacts
}

export const setContactsRecords = (contactId: string, timestamp: number, type: string): void => {
    const el = records.find((el) => el.to === contactId);
    if (el !== undefined) {
        el.data[type] = timestamp;
    } else {
        records.push({
            to: contactId,
            data: {
                [type]: timestamp,
            },
        });
    }
    if(timer === null) {
        timer = setInterval(() => {
            records.forEach((record: ContactRecord) => {
                API.post('/storage/records', record).catch(console.error);
            });
            records = [];
        }, 5000);
    }
};

export const setContactsClearedAtRecord = (contactId: string, timestamp: number): void => {
    API.post("/storage/records", {
        to: getNodeFromJid(contactId),
        data: {
            clearedAt: timestamp
        }
    }).catch(console.error)
}

export function getNodeFromJid(jid: string): string {
    return jid.split('@')[0];
}

export function getBareJid(jid: string): string {
    return Strophe.getBareJidFromJid(jid);
}

export const requestLastSeenOfContact = async (xmpp: XMPPService, jid: string): Promise<string | null> => {
    return await new Promise((resolve) => {
        xmpp.requestContactLastSeen(jid, (value: Element) => {
            const json = xmlToJson(value);
            resolve(json.query.seconds)
        }, (error: Element) => {
            console.error('LAST SEEN', jid, error)
            resolve(null)
        })

    })
}

export const fetchVirtualCardForContact = async (jid: string): Promise<VirtualCard> => {
    return await new Promise((resolve, reject) => {
        xmpp.fetchContactVirtualCard(jid, (value: Element) => {
            const json = xmlToJson(value)

            let NICK = json.vCard.NICK
            if (typeof json.vCard.NICK === 'string') NICK = json.vCard.NICK
            else if (typeof json.vCard.NICK === 'object') NICK = json.vCard.NICK['#text']

            let IMG = json.vCard.IMG
            if (typeof json.vCard.IMG === 'string') IMG = json.vCard.IMG
            else if (typeof json.vCard.IMG === 'object') IMG = json.vCard.IMG['#text']

            let PK = json.vCard.PK
            if (typeof json.vCard.PK === 'string') PK = json.vCard.PK
            else if (typeof json.vCard.PK === 'object') PK = json.vCard.PK['#text']

            resolve({
                userId: getNodeFromJid(jid),
                data: {
                    NICK,
                    IMG,
                    PK
                }
            })
        }, (error: any) => {
            console.error('VCARD', jid, error)
            reject(error)
        })

    })
}

export const generateEmptyVirtualCard = (jid: string): VirtualCard => ({
    userId: jid,
    data: {
        NICK: '',
        IMG: '',
        PK: ''
    }
})

export const parseContactChatSettings = (settings: ChatSettings): ParsedChatSettings => {
    const from: ParsedChatSettings['from'] = {
        BLOCK: false,
        TRANSLATION: false,
        NOTIFICATION: false,
        READ_RECEIPT: true,
    }

    const to: ParsedChatSettings['to'] = {
        BLOCK: false,
        TRANSLATION: false,
        NOTIFICATION: false,
        READ_RECEIPT: true,
    }

    chatSettingTypes.forEach((key: ChatSettingType) => {
        const hasFromValue = settings.from.find(el => el.type === key)
        const hasToValue = settings.to.find(el => el.type === key)

        from[key] = (hasFromValue != null) ? hasFromValue.value : false
        to[key] = (hasToValue != null) ? hasToValue.value : false
    })

    return { from, to }
}


export const changeContactChatSetting = async (contact: Contact, type: ChatSettingType, value: boolean): Promise<AxiosResponse | false> => {
    try {
        const contactId = getNodeFromJid(contact.jid)

        const response = await API.post(`/chat/settings?toId=${contactId}`, {
            toId: contactId,
            type,
            value
        })

        return response
    }
    catch (error) {
        console.error(error)
        return false
    }
}

export const fetchMe = async (): Promise<UserDataResponse | false> => {
    try {
        const response = await API.get('/users/me')

        if (response.status === 200) {
            return response.data
        }
        return false
    }
    catch (error) {
        console.error(error)
        localStorage.clear()
        logoutApp().catch(console.error)
        return false
    }
}

export const fetchContactChatSettings = async (contact: Contact): Promise<ParsedChatSettings | false> => {
    try {
        const contactId = getNodeFromJid(contact.jid)
        const response = await API.get(`/chat/settings?toId=${contactId}`)

        if (response.status === 200) {
            const parsedSettings = parseContactChatSettings(response.data)
            return parsedSettings
        }
        return false
    }
    catch (error) {
        console.error(error)
        return false
    }
}

export const sortContactsByLastMessage = (contacts: Contact[], messages: MessagesState): Contact[] => contacts.sort((a: Contact, b: Contact) => {
    const aId = getNodeFromJid(a.jid);
    const bId = getNodeFromJid(b.jid);

    const aMessages: DecryptedMessage[] = messages[aId]
    const bMessages: DecryptedMessage[] = messages[bId]

    const aLastMessage = aMessages !== undefined && aMessages.length > 0 ? aMessages[aMessages.length - 1] : null
    const bLastMessage = bMessages !== undefined && bMessages.length > 0 ? bMessages[bMessages.length - 1] : null

    if ((aLastMessage != null) && (bLastMessage == null)) return -1
    else if ((aLastMessage == null) && (bLastMessage != null)) return 1
    else if ((aLastMessage == null) && (bLastMessage == null)) return 0
    else if ((aLastMessage != null) && (bLastMessage != null))
        return aLastMessage.timestamp < bLastMessage.timestamp ? 1 : -1
    else return 0
})

export const sortMessages = (state: ContactsState, contactId: string, newMessage: DecryptedMessage): DecryptedMessage[] => {
    return [...state.messages[contactId], newMessage]
        .sort((a: DecryptedMessage, b: DecryptedMessage) => {
            return a.timestamp < b.timestamp ? -1 : 1
        })
}

export const generateInsertingMessage = (body: EncryptedMessage): ContactMessageData => {
    const myId = getNodeFromJid(xmpp.connection.jid)
    const fromId = getNodeFromJid(body.fid)
    const toId = getNodeFromJid(body.tid)

    const received = fromId !== myId
    const contactId = received ? fromId : toId
    const attachment = body.at !== undefined ? JSON.parse(body.at) : null

    const messageContent = { ...body, at: attachment }

    return {
        contactId,
        received,
        message: messageContent
    }
}


export const fetchChatSettings = async (jid: string): Promise<ParsedChatSettings | false> => {
    try {
        const contactId = getNodeFromJid(jid)
        const response = await API.get(`/chat/settings?toId=${contactId}`)

        if (response.status === 200) {
            const parsedSettings = parseContactChatSettings(response.data)
            return parsedSettings
        }
        else return false
    }
    catch (error) {
        console.error(error)
        return false
    }
}

export async function addPendingContact(xmpp: XMPPService, dispatch: AppDispatch, jid: string): Promise<void> {
    const virtualCard = await fetchVirtualCardForContact(jid)

    const contact: Contact = {
        jid,
        key: virtualCard.data.PK,
        avatar: virtualCard.data.IMG,
        name: virtualCard.data.NICK,
    }

    dispatch(addContact(contact))
    dispatch(sortContacts())

    xmpp.getLastMessagesByJid(jid, generateTimestamp())

    xmpp.connection.roster.add(jid, contact.name, [])
    xmpp.connection.roster.subscribe(jid, null, contact.name)
}

export async function addHiddenContact(jid: string, dispatch: AppDispatch): Promise<void> {
    const card = await fetchVirtualCardForContact(jid)
    console.log('adding hidden contact', jid, card)
    if (card.data.NICK === null || card.data.PK === null) return

    const contact: Contact = {
        name: card.data.NICK,
        jid,
        key: card.data.PK,
        avatar: card.data.IMG,
        isHidden: true
    }
    console.log('adding hidden contact', contact)
    dispatch(addContact(contact))
}


export async function handleContacts(newContacts: Contact[], user: User, dispatch: AppDispatch): Promise<Contact[]> {
    console.log('[FETCH CARDS]', newContacts)

    let items: Contact[] = []

    const contacts = newContacts.filter((item) => !isGroup(item.jid)).map(async (item) => {
        const card = await fetchVirtualCardForContact(item.jid)

        return {
            ...item,
            name: card.data.NICK,
            jid: item.jid,
            key: card.data.PK,
            avatar: card.data.IMG,
            type: ContactTypes.CONTACT
        }
    })

    const groups = newContacts.filter((item) => isGroup(item.jid)).map(async (item) => {
        if (user === null) return false
        const groupMeta = await fetchGroupMeta(getNodeFromJid(item.jid), dispatch)
        if (groupMeta === false) return false
        const group = await fetchGroupDetails(getNodeFromJid(item.jid), user, dispatch)
        if (group === false) return false
        let members: GroupUser[] = []

        if (['owner', 'admin', 'member'].includes(groupMeta.affiliation)) {
            members = await fetchGroupMembers(getNodeFromJid(item.jid), dispatch)
        }

        return {
            ...item,
            name: group.name,
            jid: item.jid,
            key: group.key,
            avatar: group.avatar ?? '',
            members,
            isGroup: true,
            type: group.type
        }
    })

    const contactItems = (await Promise.allSettled(contacts)).filter((item) => item.status === 'fulfilled')
        .map((item) => (item as PromiseFulfilledResult<Contact>).value)

    const groupItems = (await Promise.allSettled(groups)).filter((item) => item.status === 'fulfilled' && item.value !== false)
        .map((item) => (item as PromiseFulfilledResult<Contact>).value)

    items = [...contactItems, ...groupItems]

    dispatch(setContactData(items))

    return items
}

export async function initializeContacts(newContacts: Contact[], oldContacts: Contact[], user: User, dispatch: AppDispatch): Promise<void> {
    let updatedContacts = updateObjects(newContacts, oldContacts, 'jid')
    updatedContacts = await updateContactsRecord(updatedContacts)
    dispatch(resetPendingRosters())
    dispatch(setContacts(updatedContacts))

    const contactData = await handleContacts(updatedContacts, user, dispatch)
    contactData.filter((item) => item.type !== ContactTypes.CONTACT).forEach((group) => {
        dispatch(addGroup({ group, user }))
    })

    const pendingContacts: string[] = []

    contactData.filter((item) => item.type === ContactTypes.PRIVATE).forEach((group) => {
        group.members?.forEach(member => {
            let contact = oldContacts.find(contact => getNodeFromJid(contact.jid) === member.id)
            if (contact === undefined) contact = updatedContacts.find(contact => getNodeFromJid(contact.jid) === member.id)

            if (contact === undefined && member.id !== user.uid && !pendingContacts.includes(member.id)) {
                addHiddenContact(member.id + "@" + HOST, dispatch).catch(console.error)
                pendingContacts.push(member.id);
            }
        })
    })

    dispatch(fetchContactMessages(contactData))
}

export async function fetchMyVirtualCard(user: User | null, dispatch: AppDispatch): Promise<void> {
    if (user === null) return

    const userData = await fetchMe()

    if (userData === false) return

    const { photo, username } = userData

    dispatch(setUserData({ avatar: photo, username }))
}

export function convertCommonSearchResultsToContact(data: CommonSearchResult): Contact[] {
    const converter = (items: CommonSearchResultUser[], isGroup: boolean): Contact[] => items.map(user => {
        const { id, name, photo } = user

        return {
            jid: id,
            name,
            avatar: photo,
            isGroup,
            key: ''
        }
    })
    const groups = converter(data.groups, true)
    const users = converter(data.users, false)
    return [...users, ...groups]
}

export function convertSearchResultsToContact(results: SearchResultUser[]): Contact[] {
    if (results === undefined) return []
    return results.map(user => {
        const { id, name, photo } = user

        return {
            jid: id,
            name,
            avatar: photo,
            key: ''
        }
    })
}

export function sortContactsByAlphabet(contacts: Contact[]): Array<{
    letter: string,
    items: Contact[]
}> {
    const letters = 'abcdefghijklmnopqrstuvwxyz'.split('')

    return letters.map(letter => {
        const items = contacts.filter(contact => contact.name?.toLowerCase().startsWith(letter))

        return {
            letter,
            items
        }
    }).filter(({ items }) => items.length > 0)
}

export function filterUniqueContacts(contacts: Contact[]): Contact[] {
    return contacts.filter((contact, index, self) => {
        const findOtherIndex = self.findIndex((t) => {
            const aJid = getNodeFromJid(t.jid)
            const bJid = getNodeFromJid(contact.jid)

            return aJid === bJid || aJid.includes(bJid) || bJid.includes(aJid)
        })

        return findOtherIndex === index
    })
}


export const fetchLastSeen = (jid: string, dispatch: AppDispatch): void => {
    requestLastSeenOfContact(xmpp, jid).then(lastSeen => {
        dispatch(setRosterState({
            type: RosterStateType.LAST_SEEN,
            value: lastSeen !== null ? lastSeen : false,
            from: jid,
        }))
    }).catch((error) => {
        console.log(error)
    })
}
