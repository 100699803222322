import * as CryptoJS from 'crypto-js'

import JSEncrypt from 'jsencrypt'
import { CallEventTypes, type CallEvent, type CallEventType, type EncryptedGroupMessage, type EncryptedMessage } from 'src/types/Ejabberd/Message';
import { MessageType } from 'src/types/Ejabberd/MessageType';
import moment from 'moment';

import { v4 as uuidv4 } from 'uuid';
import { SERVER_PUBLIC_KEY } from '../constants/api'
import { type ContactAttachment, type DecryptedMessage, type ImageAttachment, type LocationShareAttachment, type VideoAttachment, type MessageParameters, type VoiceAttachment, type FileAttachment, type MessageAdditionalData, type GIFAttachment } from 'src/types/Message';
import { type User } from "src/types/User";
import { getNodeFromJid } from './contact';
import { type Contact, type ContactMessageData } from 'src/types/Contact';
import { type GroupMessage } from 'src/types/Group';
import { HOST, xmpp } from 'src/constants/xmpp';
import { addCallEvent, addGroupCallEvent, addMessage, fixDateHeaders, sortContacts, updateMessageDeliveryStatuses } from 'src/store/slices/contacts';
import { type AppDispatch } from 'src/store';
import { isJSON } from './common';
import uupStorage from 'src/contexts/DB';
import { CallFormats, type CallData, CallTypes } from 'src/types/call';
import { EventBus } from 'src/services/EventBus';
import { UupEvents } from 'src/constants/events';
import { handleOngoingCallEvent, resetCallState } from "src/store/slices/call";

export const generateTimestamp = (): number => fixTimestamp(moment().utc().unix());

export function decryptRSA(data: string, key: string): string | false {
    const encrypt = new JSEncrypt({
        default_key_size: '2048'
    })

    encrypt.setPrivateKey(key)
    const decrypted: string | boolean = encrypt.decrypt(data)

    return decrypted
}

export function decryptAesIv(msg: EncryptedMessage, key: string): string | false {
    try {
        const { uk } = msg

        const encrypt = new JSEncrypt({
            default_key_size: '2048'
        })

        encrypt.setPrivateKey(key)
        const decrypted: string | false = encrypt.decrypt(uk)
        
        return decrypted !== null ? decrypted : false
    } catch (error) {
        console.error('[DECRYPT] error', error, msg, key)
        return false
    }
}

export function encryptRSA(message: string, publicKey: string): string | false {
    const encrypt = new JSEncrypt({
        default_key_size: '2048'
    })

    encrypt.setPublicKey(publicKey)
    return encrypt.encrypt(message)
}

export function encryptAES(message: string, aes: string, iv: string): string {
    const encrypted = CryptoJS.AES.encrypt(
        message,
        CryptoJS.enc.Base64.parse(aes),
        {
            iv: CryptoJS.enc.Base64.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        })

    return encrypted.toString()
}

export function decryptAES(content: string, aes: string, iv: string, base64?: boolean): string | false {
    try {
        if (content === undefined) return false

        const ciphertextWA = CryptoJS.enc.Base64.parse(content);
        const keyWA = CryptoJS.enc.Base64.parse(aes);
        const ivWA = CryptoJS.enc.Base64.parse(iv);
        const ciphertextCP: any = { ciphertext: ciphertextWA };

        const decrypted = CryptoJS.AES.decrypt(
            ciphertextCP,
            keyWA,
            {
                iv: ivWA,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }
        );

        return decrypted.toString(base64 === true ? CryptoJS.enc.Base64 : CryptoJS.enc.Utf8)
    } catch (error) {
        console.error('[DECRYPT] error', error, content, aes, iv)
        return false
    }
}

export function decryptReceivedMessage(receivedMessage: EncryptedMessage, userData: User): DecryptedMessage | false {
    const decryptedAesIv = decryptAesIv(receivedMessage, userData.privateKey)
    if (decryptedAesIv === false) return false
    const [ivStr, aesStr] = decryptedAesIv.split(',')

    const commonParams = {
        oldId: receivedMessage.oldId,
        id: receivedMessage.mid,
        type: receivedMessage.t,
        fromId: receivedMessage.fid,
        toId: receivedMessage.tid,
        quotedMessageId: receivedMessage.qid ?? undefined,
        timestamp: fixTimestamp(receivedMessage.ts),
        mamId: receivedMessage.mamId ?? null,
        received: true,
        isDelivered: true,
        isRead: false
    }

    if (receivedMessage.t === MessageType.TEXT && receivedMessage.c !== undefined) {
        const decrypted = decryptAES(receivedMessage.c, aesStr, ivStr)
        if (decrypted === false) return false

        return {
            ...commonParams,
            content: decrypted,
            attachment: receivedMessage.at,
            updatedAt: receivedMessage.ut,
        }
    }
    else if (receivedMessage.t === MessageType.VOICE) {
        const attachmentData = decryptAES(receivedMessage.at.dt, aesStr, ivStr)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(receivedMessage.at.md) ? JSON.parse(receivedMessage.at.md) : {
            d: 0,
            s: 0
        }

        const attachment: VoiceAttachment = {
            data: attachmentData,
            metaData: {
                duration: parsedMetaData.d,
                size: parsedMetaData.s,
            },
        }
        return {
            ...commonParams,
            attachment,
        }
    }
    else if (receivedMessage.t === MessageType.VIDEO) {
        const attachmentData = decryptAES(receivedMessage.at.dt, aesStr, ivStr)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(receivedMessage.at.md) ? JSON.parse(receivedMessage.at.md) : {
            d: 0,
            s: 0
        }

        const attachment: VideoAttachment = {
            data: attachmentData,
            thumbnail: receivedMessage.at.tb,
            metaData: {
                duration: parsedMetaData.d,
                size: parsedMetaData.s,
            },
        }
        return {
            ...commonParams,
            attachment,
        }
    }
    else if (receivedMessage.t === MessageType.IMAGE) {
        const attachmentData = decryptAES(receivedMessage.at.dt, aesStr, ivStr)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(receivedMessage.at.md) ? JSON.parse(receivedMessage.at.md) : {
            s: 0
        }

        const attachment: ImageAttachment = {
            data: attachmentData,
            thumbnail: receivedMessage.at.tb,
            metaData: {
                size: parsedMetaData.s,
            },
        }
        return {
            ...commonParams,
            attachment
        }
    }
    else if (receivedMessage.t === MessageType.GIF) {
        const attachmentData = decryptAES(receivedMessage.at.dt, aesStr, ivStr)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(receivedMessage.at.md) ? JSON.parse(receivedMessage.at.md) : {
            s: 0
        }

        const attachment: GIFAttachment = {
            data: attachmentData,
            metaData: {
                width: parsedMetaData.w,
                height: parsedMetaData.h,
                size: parsedMetaData.s,
            },
        }
        return {
            ...commonParams,
            attachment
        }
    }
    else if (receivedMessage.t === MessageType.FILE) {
        const attachmentData = decryptAES(receivedMessage.at.dt, aesStr, ivStr)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(receivedMessage.at.md) ? JSON.parse(receivedMessage.at.md) : {
            n: '',
            s: ''
        }

        const attachment: FileAttachment = {
            data: attachmentData,
            metaData: {
                name: parsedMetaData.n,
                size: parsedMetaData.s,
            }
        }

        const decryptedMessage: DecryptedMessage = {
            ...commonParams,
            attachment
        }


        return decryptedMessage
    }
    else if (receivedMessage.t === MessageType.LOCATION || receivedMessage.t === MessageType.LIVE_LOCATION) {
        const attachmentData = decryptAES(receivedMessage.at.dt, aesStr, ivStr)
        if (attachmentData === false) return false

        const { lat, lng, name } = JSON.parse(attachmentData)

        const attachment: LocationShareAttachment = {
            latitude: lat,
            longitude: lng,
            name,
            thumbnail: receivedMessage.at.tb
        }
        return {
            ...commonParams,
            attachment
        }
    }
    else if (receivedMessage.t === MessageType.CONTACT) {
        const attachmentData = decryptAES(receivedMessage.at.dt, aesStr, ivStr)
        if (attachmentData === false) return false

        const { numbers, name } = JSON.parse(attachmentData)

        const attachment: ContactAttachment = {
            name,
            numbers
        }
        return {
            ...commonParams,
            attachment
        }
    }
    else return false
}

export function decryptSentMessage(message: EncryptedMessage, userData: User): DecryptedMessage | false {
    const timestamp = fixTimestamp(message.ts)

    const commonParams = {
        oldId: message.oldId,
        id: message.mid,
        type: message.t,
        fromId: message.fid,
        toId: message.tid,
        quotedMessageId: message.qid ?? undefined,
        timestamp,
        received: false,
        mamId: message.mamId ?? null,
        isUploaded: message.isUploaded ?? true
    }

    if (message.t === MessageType.TEXT && message.c !== undefined) {
        if (message.mc === undefined) return false
        const sentContent = decryptAES(message.mc, userData.aes, userData.iv)
        if (sentContent === false) return false

        return {
            ...commonParams,
            content: sentContent,
            attachment: message.at
        }
    }
    else if (message.at === undefined) return false
    else if (message.t === MessageType.VOICE) {
        const attachmentData = decryptAES(message.at.mdt, userData.aes, userData.iv)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(message.at.md) ? JSON.parse(message.at.md) : {
            d: 0,
            s: 0
        }

        const attachment: VoiceAttachment = {
            data: attachmentData,
            metaData: {
                duration: parsedMetaData.d,
                size: parsedMetaData.s,
            }
        }
        return {
            ...commonParams,
            attachment
        }
    }
    else if (message.t === MessageType.VIDEO) {
        const attachmentData = decryptAES(message.at.mdt, userData.aes, userData.iv)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(message.at.md) ? JSON.parse(message.at.md) : {
            d: 0,
            s: 0
        }

        const attachment: VideoAttachment = {
            data: attachmentData,
            thumbnail: message.at.tb,
            metaData: {
                duration: parsedMetaData.d,
                size: parsedMetaData.s,
            }
        }
        return {
            ...commonParams,
            attachment,
            abortControllerId: message.abortControllerId
        }
    }
    else if (message.t === MessageType.FILE) {
        const attachmentData = decryptAES(message.at.mdt, userData.aes, userData.iv)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(message.at.md) ? JSON.parse(message.at.md) : {
            n: '',
            s: 0
        }

        const attachment: FileAttachment = {
            data: attachmentData,
            metaData: {
                name: parsedMetaData.n,
                size: parsedMetaData.s,
            }
        }
        return {
            ...commonParams,
            attachment,
            quotedMessageId: message.qid ?? undefined,
            abortControllerId: message.abortControllerId
        }
    }
    else if (message.t === MessageType.IMAGE) {
        const attachmentData = decryptAES(message.at.mdt, userData.aes, userData.iv)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(message.at.md) ? JSON.parse(message.at.md) : {
            s: 0
        }

        const attachment: ImageAttachment = {
            data: attachmentData,
            thumbnail: message.at.tb,
            metaData: {
                size: parsedMetaData.s,
            }
        }
        return {
            ...commonParams,
            attachment,
            abortControllerId: message.abortControllerId
        }
    }
    else if (message.t === MessageType.GIF) {
        const attachmentData = decryptAES(message.at.mdt, userData.aes, userData.iv)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(message.at.md) ? JSON.parse(message.at.md) : {
            s: 0
        }

        const attachment: GIFAttachment = {
            data: attachmentData,
            metaData: {
                width: parsedMetaData.w,
                height: parsedMetaData.h,
                size: parsedMetaData.s,
            }
        }
        return {
            ...commonParams,
            attachment,
            abortControllerId: message.abortControllerId
        }
    }
    else if (message.t === MessageType.LOCATION || message.t === MessageType.LIVE_LOCATION) {
        const attachmentData = decryptAES(message.at.mdt, userData.aes, userData.iv)
        if (attachmentData === false) return false

        const { lat, lng, name } = JSON.parse(attachmentData)

        const attachment: LocationShareAttachment = {
            latitude: lat,
            longitude: lng,
            name,
            thumbnail: message.at.tb
        }
        return {
            ...commonParams,
            attachment
        }
    }
    else if (message.t === MessageType.CONTACT) {
        const attachmentData = decryptAES(message.at.mdt, userData.aes, userData.iv)
        if (attachmentData === false) return false

        const { numbers, name } = JSON.parse(attachmentData)

        const attachment: ContactAttachment = {
            name,
            numbers
        }
        return {
            ...commonParams,
            attachment
        }
    }
    else return false
}

export const generateAes = (length: number = 32): string => CryptoJS.lib.WordArray.random(length).toString(CryptoJS.enc.Base64)
export const generateIv = (length: number = 16): string => CryptoJS.lib.WordArray.random(length).toString(CryptoJS.enc.Base64)

export const generateEncryptedMessageContent = (
    type: MessageType,
    content: string,
    toId: string,
    publicKey: string,
    generatedAes: string | null = null,
    generatedIv: string | null = null,
    userData: User,
    additional: MessageAdditionalData = {}
): EncryptedMessage | false => {
    const newAes = generatedAes ?? generateAes()
    const newIv = generatedIv ?? generateIv()
    const userKey = `${newIv},${newAes}`
    const encryptedMessage = encryptAES(content, newAes, newIv)
    const myEncryptedMessage = encryptAES(content, userData.aes, userData.iv)
    const encryptedUserKey = encryptRSA(userKey, publicKey)
    const encryptedServerKey = encryptRSA(userKey, SERVER_PUBLIC_KEY)

    if (encryptedUserKey === false || encryptedServerKey === false) return false

    const timestamp = generateTimestamp()
    const messageId = uuidv4()

    if (type === MessageType.TEXT) {
        return {
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            c: encryptedMessage,
            uk: encryptedUserKey,
            sk: encryptedServerKey,
            t: type,
            mc: myEncryptedMessage,
            ts: timestamp.toString(),
            ...additional
        }
    } else if (type === MessageType.VOICE) {
        return {
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            uk: encryptedUserKey,
            sk: encryptedServerKey,
            t: type,
            at: content,
            ts: timestamp.toString(),
            ...additional
        }
    } else if (type === MessageType.IMAGE) {

        return {
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            uk: encryptedUserKey,
            sk: encryptedServerKey,
            t: type,
            at: content,
            ts: timestamp.toString(),
            ...additional
        }
    }
    else if (type === MessageType.GIF) {
        return {
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            uk: encryptedUserKey,
            sk: encryptedServerKey,
            t: type,
            at: content,
            ts: timestamp.toString(),
            ...additional
        }
    }
    else if (type === MessageType.VIDEO) {

        return {
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            uk: encryptedUserKey,
            sk: encryptedServerKey,
            t: type,
            at: content,
            ts: timestamp.toString(),
            ...additional
        }
    }
    else if (type === MessageType.FILE) {

        return {
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            uk: encryptedUserKey,
            sk: encryptedServerKey,
            t: type,
            at: content,
            ts: timestamp.toString(),
            ...additional
        }
    }

    else return false
}

export const prepareEncryptedForwardingMessageContent = (
    message: DecryptedMessage,
    toId: string,
    publicKey: string,
    generatedAes: string | null = null,
    generatedIv: string | null = null,
    userData: User,
    additional: object = {}
): EncryptedMessage | false => {
    const newAes = generatedAes ?? generateAes()
    const newIv = generatedIv ?? generateIv()
    const userKey = `${newIv},${newAes}`
    const encryptedUserKey = encryptRSA(userKey, publicKey)
    const encryptedServerKey = encryptRSA(userKey, SERVER_PUBLIC_KEY)

    const { type } = message

    if (encryptedUserKey === false || encryptedServerKey === false) return false

    const timestamp = generateTimestamp()
    const messageId = uuidv4()

    if (type === MessageType.TEXT) {
        const encryptedMessage = encryptAES(message.content ?? '', newAes, newIv)
        const myEncryptedMessage = encryptAES(message.content ?? '', userData.aes, userData.iv)

        return {
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            c: encryptedMessage,
            uk: encryptedUserKey,
            sk: encryptedServerKey,
            t: type,
            mc: myEncryptedMessage,
            ts: timestamp.toString(),
            ...additional
        }
    } else if (type === MessageType.VOICE) {
        const attachment = message.attachment as VoiceAttachment
        return {
            oldId: message.id,
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            uk: encryptedUserKey,
            sk: encryptedServerKey,
            t: type,
            at: JSON.stringify({
                mdt: encryptAES(attachment.data, userData.aes, userData.iv),
                dt: encryptAES(attachment.data, newAes, newIv),
                md: JSON.stringify({
                    d: attachment.metaData.duration,
                    s: attachment.metaData.size,
                })
            }),
            ts: timestamp.toString(),
            ...additional
        }
    } else if (type === MessageType.IMAGE) {
        const attachment = message.attachment as ImageAttachment

        return {
            oldId: message.id,
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            uk: encryptedUserKey,
            sk: encryptedServerKey,
            t: type,
            at: JSON.stringify({
                dt: encryptAES(attachment.data, newAes, newIv),
                mdt: encryptAES(attachment.data, userData.aes, userData.iv),
                md: JSON.stringify({
                    s: attachment.metaData.size,
                    w: attachment.metaData.width,
                    h: attachment.metaData.height
                }),
                tb: attachment.thumbnail,
            }),
            ts: timestamp.toString(),
            ...additional
        }
    } else if (type === MessageType.VIDEO) {
        const attachment = message.attachment as VideoAttachment

        return {
            oldId: message.id,
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            uk: encryptedUserKey,
            sk: encryptedServerKey,
            t: type,
            at: JSON.stringify({
                dt: encryptAES(attachment.data, newAes, newIv),
                tb: attachment.thumbnail,
                mdt: encryptAES(attachment.data, userData.aes, userData.iv),
                md: JSON.stringify({
                    d: attachment.metaData.duration,
                    s: attachment.metaData.size,
                })
            }),
            ts: timestamp.toString(),
            ...additional
        }
    }

    else if (type === MessageType.GIF) {
        const attachment = message.attachment as GIFAttachment

        return {
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            uk: encryptedUserKey,
            sk: encryptedServerKey,
            t: type,
            at: JSON.stringify({
                dt: encryptAES(attachment.data, newAes, newIv),
                mdt: encryptAES(attachment.data, userData.aes, userData.iv),
                md: JSON.stringify({
                    s: attachment.metaData.size ?? 0,
                    w: attachment.metaData.width ?? 0,
                    h: attachment.metaData.height ?? 0
                })
            }),
            ts: timestamp.toString(),
            ...additional
        }
    }
    else if (type === MessageType.FILE) {
        const attachment = message.attachment as FileAttachment

        return {
            oldId: message.id,
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            uk: encryptedUserKey,
            sk: encryptedServerKey,
            t: type,
            at: JSON.stringify({
                dt: encryptAES(attachment.data, newAes, newIv),
                mdt: encryptAES(attachment.data, userData.aes, userData.iv),
                md: JSON.stringify({
                    n: attachment.metaData.name,
                    s: attachment.metaData.size
                })
            }),
            ts: timestamp.toString(),
            ...additional
        }
    }

    else return false
}

export const prepareEncryptedGroupForwardingMessageContent = (
    message: DecryptedMessage,
    toId: string,
    userData: User,
    group: Contact,
    additional: object = {}
): EncryptedGroupMessage | false => {
    const [iv, aes] = group.key.split(',')

    const { type } = message

    const timestamp = generateTimestamp()
    const messageId = uuidv4()

    if (type === MessageType.TEXT) {
        const encryptedMessage = encryptAES(message.content ?? '', aes, iv)

        return {
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            c: encryptedMessage,
            t: type,
            ts: timestamp.toString(),
            ...additional
        }
    } else if (type === MessageType.VOICE) {
        const attachment = message.attachment as VoiceAttachment
        return {
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            t: type,
            sk: "null",
            at: JSON.stringify({
                dt: encryptAES(attachment.data, aes, iv),
                mdt: encryptAES(attachment.data, userData.aes, userData.iv),
                md: JSON.stringify({
                    d: attachment.metaData.duration,
                    s: attachment.metaData.size
                })
            }),
            ts: timestamp.toString(),
            ...additional
        }
    } else if (type === MessageType.IMAGE) {
        const attachment = message.attachment as ImageAttachment

        return {
            oldId: message.id,
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            t: type,
            sk: "null",
            at: JSON.stringify({
                dt: encryptAES(attachment.data, aes, iv),
                mdt: encryptAES(attachment.data, userData.aes, userData.iv),
                md: JSON.stringify({
                    s: attachment.metaData.size,
                    w: attachment.metaData.width,
                    h: attachment.metaData.height
                }),
                tb: attachment.thumbnail,
            }),
            ts: timestamp.toString(),
            ...additional
        }
    } else if (type === MessageType.VIDEO) {
        const attachment = message.attachment as VideoAttachment

        return {
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            t: type,
            sk: "null",
            at: JSON.stringify({
                dt: encryptAES(attachment.data, aes, iv),
                tb: attachment.thumbnail,
                mdt: encryptAES(attachment.data, userData.aes, userData.iv),
                md: JSON.stringify({
                    d: attachment.metaData.duration,
                    s: attachment.metaData.size,
                })
            }),
            ts: timestamp.toString(),
            ...additional
        }
    }
    else if (type === MessageType.GIF) {
        const attachment = message.attachment as GIFAttachment

        return {
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            t: type,
            at: JSON.stringify({
                dt: encryptAES(attachment.data, aes, iv),
                mdt: encryptAES(attachment.data, userData.aes, userData.iv),
                md: JSON.stringify({
                    s: attachment.metaData.size ?? 0,
                    w: attachment.metaData.width ?? 0,
                    h: attachment.metaData.height ?? 0
                })
            }),
            ts: timestamp.toString(),
            ...additional
        }
    }
    else if (type === MessageType.FILE) {
        const attachment = message.attachment as FileAttachment

        return {
            mid: messageId,
            fid: userData.uid,
            tid: toId,
            t: type,
            sk: "null",
            at: JSON.stringify({
                dt: encryptAES(attachment.data, aes, iv),
                mdt: encryptAES(attachment.data, userData.aes, userData.iv),
                md: JSON.stringify({
                    n: attachment.metaData.name,
                    s: attachment.metaData.size
                })
            }),
            ts: timestamp.toString(),
            ...additional
        }
    }

    else return false
}

export function fixTimestamp(timestamp: string | number): number {
    const ts: number = typeof timestamp === 'string' ? parseInt(timestamp) : timestamp

    const diff = 13 - ts.toString().length

    if (diff > 0) {
        return ts * Math.pow(10, diff)
    } else return ts
}

export function parseTimestamp(timestamp: string | number): number {
    const ts: number = typeof timestamp === 'string' ? parseInt(timestamp) : timestamp
    const diff = ts.toString().length - 10

    if (diff > 0) {
        return ts / Math.pow(10, diff)
    }

    return ts
}


export const replaceWordWithEmoji = (word: string, emoji: string): string => {
    const formatMsg = word.trim()
    const lastWordIdx = formatMsg.lastIndexOf(' ')
    return formatMsg.substring(0, lastWordIdx) + ' ' + emoji
}

export function generateContactMessage(body: EncryptedMessage, params: MessageParameters): ContactMessageData {
    const myId = getNodeFromJid(xmpp.connection.jid)
    const fromId = getNodeFromJid(body.fid)
    const toId = getNodeFromJid(body.tid)

    const received = toId === myId
    const contactId = received ? fromId : toId
    const attachment = (body.at !== undefined && body.at.length > 0) ? JSON.parse(body.at) : undefined

    const messageContent = {
        ...body,
    }

    if (attachment !== undefined) messageContent.at = attachment

    return {
        contactId,
        received,
        message: { ...messageContent, ...params }
    }
}


export function generateGroupMessage(body: EncryptedGroupMessage, params: MessageParameters): GroupMessage {
    const myId = getNodeFromJid(xmpp.connection.jid)
    const fromId = getNodeFromJid(body.fid)
    const groupId = getNodeFromJid(body.tid)

    const received = fromId !== myId
    const attachment = body.at !== undefined ? JSON.parse(body.at) : null

    const messageContent = { ...body, at: attachment, ...params }

    return {
        groupId, received,
        data: messageContent
    }
}

export function parseMessageParameters(xml: Element): MessageParameters {
    try {
        const message = xml.getElementsByTagName('message')
        if (message.length === 0) return {}

        const archived = message[0].getElementsByTagName('archived')
        if (archived.length === 0) return {}

        const mamId = archived[0].getAttribute('id')

        return {
            mamId
        }
    } catch (error) {
        console.log('error parsing message parameters', error, xml)
        return {}
    }
}

export function xmppMessageHandler(isArchived: boolean, json: EncryptedMessage, xml: Element, user: User | null, dispatch: AppDispatch): void {
    if (json.fid?.length === 0 || (user == null)) return

    const params = parseMessageParameters(xml)
    const messageData = generateContactMessage(json, params)

    if (!isArchived)
        xmpp.sendReceivedEvent(`${json.fid}@${HOST}`, json.mid)
    dispatch(addMessage({ data: messageData, user }))
    dispatch(updateMessageDeliveryStatuses(messageData.contactId))
    dispatch(fixDateHeaders({ contactId: messageData.contactId }))
}


export function insertMessage(body: EncryptedMessage, user: User | null, dispatch: AppDispatch, additional?: MessageParameters): void {
    if (xmpp.connection === undefined || user === null) return

    const content = generateContactMessage(body, additional ?? {})

    console.log('inserting', content)

    dispatch(addMessage({
        data: content,
        user
    }))
    dispatch(sortContacts())

    if ([MessageType.TEXT, MessageType.CONTACT, MessageType.GIF, MessageType.LOCATION, MessageType.LIVE_LOCATION].includes(body.t)) {
        const contactId = body.fid === user.uid ? body.tid : body.fid
        dispatch(updateMessageDeliveryStatuses(contactId))
    }

    dispatch(fixDateHeaders({ contactId: content.contactId }))
}


export const saveFileToDisk = async (messageId: string, blob: Blob): Promise<void> => {
    try {
        console.log('[MESSAGE] Save to disk', `attachment-${messageId}`)
        await uupStorage.setItem(`attachment-${messageId}`, blob)
    } catch (error) {
        console.error('[MESSAGE] Save to disk', error)
    }
}

export function formatDateHeader(timestamp: number): string {
    // const isToday = moment.unix(timestamp).isSame(moment(), 'day')
    // const isYesterday = moment.unix(timestamp).isSame(moment().subtract(1, 'day'), 'day')

    // if (isToday) return 'Today'
    // else if (isYesterday) return 'Yesterday'
    // else
    return moment.unix(timestamp).calendar()
}

export function xmppCallEventHandler(json: CallEvent, xml: Element, user: User | null, dispatch: AppDispatch): void {
    if (json.fid === null || user === null) return

    const isGroupCall = json.cl.t === CallFormats.CONFERENCE_VIDEO || json.cl.t === CallFormats.CONFERENCE_VOICE
    const isEndEvent = [CallEventTypes.REJECTED, CallEventTypes.MISSED, CallEventTypes.ENDED].includes(json.c as CallEventTypes)
    const isOngoingEvent = [CallEventTypes.ONGOING].includes(json.c as CallEventTypes)

    if (isGroupCall){
        dispatch(addGroupCallEvent({
            event: json
        }))
        dispatch(fixDateHeaders({ contactId: json.tid }))
    }
    else { 
        dispatch(addCallEvent({
        event: json,
        user
        }))
        const userId =  json.fid === user?.uid ? json.tid : json.fid  
        dispatch(fixDateHeaders({ contactId: userId }))
    }

    if (isEndEvent) {
        EventBus.next({ type: UupEvents.CALL_ENDED })
        dispatch(resetCallState())
    }

    if (isOngoingEvent) {
        const callData: CallData = {
            format: CallFormats.VOICE,
            type: CallTypes.OUTGOING,
            caller: {
                id: getNodeFromJid(json.fid),
                username: "",
            },
            channelId: null,
            callId: json.cl.id
        }
        dispatch(handleOngoingCallEvent(callData))
    }
}

export function parseCallEvent(message: CallEvent, received: boolean): DecryptedMessage | false {
    if (message.c === undefined) return false
    const eventType = message.c as CallEventType
    // if (eventType === CallEventTypes.STARTED) return false

    let content;
    switch (eventType) {
        // case CallEventTypes.STARTED:
        //     content = "Call started"
        //     break;
        case CallEventTypes.ENDED:
            content = "Call ended"
            break;
        case CallEventTypes.MISSED:
            content = "Missed call"
            break;
        case CallEventTypes.REJECTED:
            content = "Call rejected"
            break;
        default:
            // console.error('Unknown call event type', eventType)
            return false
    };

    const duration = message.cl.d !== undefined ? parseInt(message.cl.d.e) - parseInt(message.cl.d.s) : undefined

    return {
        type: message.t,
        id: message.mid,
        fromId: message.fid,
        toId: message.tid,
        content,
        timestamp: parseInt(message.ts),
        received,
        additional: {
            type: message.cl.t,
            duration
        }
    }
}

