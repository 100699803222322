import moment from 'moment';
import { useMemo, useCallback, useState } from 'react';
import { parseTimestamp, saveFileToDisk } from 'src/helpers/message';
import { type DecryptedMessage, type ImageAttachment } from 'src/types/Message';
import LoadingImage from 'src/components/LoadingImage';
import uupStorage from 'src/contexts/DB';
import { getAWSSignedUrl } from 'src/helpers/file';
import axios from 'axios';
import MessageDeliveryTicks from 'src/components/UI/MessageDeliveryTicks';
import { useAppDispatch, useContacts } from 'src/hooks/store';
import { showGallery } from 'src/store/slices/contacts';
import { MessageType } from 'src/types/Ejabberd/MessageType';
import clsx from 'clsx';
import { UupEvents } from 'src/constants/events';
import { EventBus } from 'src/services/EventBus';
interface Props {
  message: DecryptedMessage;
  isGalleryItem?: boolean;
  isMainGalleryItem?: boolean;
}
interface DownloadedData {
  url: string;
  blob: Blob;
}
function ImageMessage({
  message,
  isGalleryItem = false,
  isMainGalleryItem = false
}: Props): JSX.Element | null {
  const dispatch = useAppDispatch();
  const {
    activeContact,
    activeGroup,
    messages
  } = useContacts();
  const {
    timestamp,
    oldId,
    received,
    abortControllerId,
    isDelivered
    // isReceived,
    // isRead
  } = message;
  const attachment = (message.attachment as ImageAttachment);
  const {
    isUploaded
  } = message;
  const [downloadedData, $downloadedData] = useState<DownloadedData | null>(null);
  const formattedTimestamp: string = moment.unix(parseTimestamp(timestamp)).format('HH:mm');
  const [error, $error] = useState<boolean>(false);
  const [progress, $progress] = useState<number | null>(null);
  const fullPath = `media/images/${attachment.data}`;
  const getStoredFile = useCallback(async (): Promise<DownloadedData | null> => {
    try {
      const attachmentId = oldId ?? message.id;
      const blob = (await uupStorage.getItem(`attachment-${attachmentId}`) as Blob);
      if (blob === null) return null;
      const url = URL.createObjectURL(blob);
      return {
        url,
        blob
      };
    } catch (error) {
      console.error('[IMAGE MESSAGE] Get stored file', error);
      return null;
    }
  }, [message.id, oldId]);
  const downloadWithSignedURL = useCallback(async () => {
    try {
      $progress(0);
      const signedData = await getAWSSignedUrl(fullPath, 'GET_OBJECT');
      if (signedData === false) return;
      const download = await axios.get(signedData.url, {
        responseType: 'blob',
        onDownloadProgress: progressEvent => {
          if (progressEvent.lengthComputable === false) return;

          // const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
          // const current = progressEvent.currentTarget.response.length
          const total = parseFloat(progressEvent.total);
          const current = progressEvent.loaded;
          const percentCompleted = Math.round(current / total * 100);
          $progress(percentCompleted);
        }
      });
      const streamContent = download.data;
      const blob = new Blob([streamContent], {
        type: 'image/jpeg'
      });
      const url = URL.createObjectURL(blob);
      saveFileToDisk(message.id, blob).catch(console.error);
      $downloadedData({
        url,
        blob
      });
    } catch (error) {
      console.error('[IMAGE MESSAGE] Start download', error);
      $error(true);
    } finally {
      $progress(null);
    }
    //
  }, [fullPath, message]);
  const handleEntry = useCallback(async (entry: IntersectionObserverEntry) => {
    if (!entry.isIntersecting) return;
    // if (!received && isUploaded !== true) return
    const storedFile = await getStoredFile();
    if (storedFile !== null) {
      $downloadedData(storedFile);
      return;
    }
    downloadWithSignedURL().catch(console.error);
  }, [getStoredFile, downloadWithSignedURL]);
  const intersectionCallback = useCallback((entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      void handleEntry(entry);
    });
  }, [handleEntry]);

  // const download = useCallback(() => {
  //     if (downloadedData?.url !== undefined) {
  //         window.open(downloadedData?.url, '_blank');
  //     }
  // }, [downloadedData?.url])

  const toggleContactMediaViewer = useCallback(() => {
    if (activeContact === null) return;
    const id = Strophe.getNodeFromJid(activeContact.jid);
    const galleryItems = messages[id].filter(message => [MessageType.IMAGE, MessageType.VIDEO].includes(message.type)).reverse();
    dispatch(showGallery({
      galleryItems,
      mainGalleryItem: message
    }));
  }, [activeContact, dispatch, message, messages]);
  const toggleGroupMediaViewer = useCallback(() => {
    if (activeGroup === null) return;
    const id = Strophe.getNodeFromJid(activeGroup.jid);
    const galleryItems = messages[id].filter(message => [MessageType.IMAGE, MessageType.VIDEO].includes(message.type)).reverse();
    dispatch(showGallery({
      galleryItems,
      mainGalleryItem: message
    }));
  }, [activeGroup, dispatch, message, messages]);

  // const renderFullScreen = useMemo(() => (
  //     <div className='bg-gray-50 absolute top-0 left-0 w-full h-screen flex flex-col items-end z-80'>
  //         <div className="relative w-full h-full flex justify-center">
  //             <img
  //                 src={downloadedData?.url ?? ''} alt='img'
  //                 onError={() => { $error(true); }}
  //                 className='w-[80%] h-[80%] object-contain'
  //             />
  //             <div className="flex items-center absolute top-2 right-4">

  //                 <button onClick={download} className='group hover:bg-primary w-8 h-8 mr-2 bg-gray-100 shadow rounded-sm transition hover:opacity-80'>
  //                     <i className="bi bi-download text-primary-600 group-hover:text-white"></i>
  //                 </button>
  //                 <button onClick={toggleMediaViewer} className='group hover:bg-red-600 w-8 h-8 flex justify-center items-center bg-gray-100 shadow rounded-sm transition hover:opacity-80'>
  //                     <i className="bi bi-x text-xl text-red-600 group-hover:text-white"></i>
  //                 </button>
  //             </div>
  //         </div>
  //     </div>

  // ), [download, downloadedData?.url, toggleMediaViewer])

  const initRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const observer = new IntersectionObserver(intersectionCallback, {
        root: null,
        rootMargin: "0px",
        threshold: 0.4
      });
      observer.observe(node);
    }
  }, [intersectionCallback]);
  const cancelUpload = useCallback(() => {
    if (abortControllerId === null) return;
    EventBus.next({
      type: UupEvents.CANCEL_UPLOAD,
      payload: {
        abortControllerId,
        messageId: message.id
      }
    });
  }, [abortControllerId, message.id]);
  const renderMessage = useMemo(() => {
    const path = downloadedData?.url ?? `data:image/png;base64,${attachment.thumbnail}`;
    const isUploading = abortControllerId !== null && abortControllerId !== undefined && isDelivered !== true;
    return <div ref={initRef} className={clsx('shrink-0 relative flex flex-col items-center text-base', isMainGalleryItem && 'h-full', isGalleryItem && 'rounded overflow-hidden mx-2')}>

            {isUploading && <div onClick={cancelUpload} className="absolute top-0 z-40 left-0 w-full h-full center-flex flex-col gap-y-2 cursor-pointer transition hover:scale-[1.1]">
                    <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center">
                        <i className="bi bi-x text-xl text-red-600"></i>
                    </div>
                </div>}

            <div className={clsx(isMainGalleryItem ? 'w-2/3 h-full' : isGalleryItem ? 'w-32 h-32' : 'w-52 h-64 center-flex')}>
                <LoadingImage url={path} skipLoading onClick={activeContact !== null ? toggleContactMediaViewer : toggleGroupMediaViewer} className={clsx('w-full h-full rounded-sm mb-0 transition hover:opacity-80 cursor-pointer', isMainGalleryItem ? 'object-contain' : 'object-cover', isUploading && 'opacity-50')} alt="thumbnail" />
            </div>

            {!isGalleryItem && <div className={clsx('absolute attachment-overlay px-2 py-2 w-full bottom-0 left-0 z-50 flex items-center justify-end', isUploading && message.uploadingProgress !== undefined && 'justify-between')}>
                {isUploading && message.uploadingProgress !== undefined && <span className='text-white text-xs'>{message.uploadingProgress}%</span>}
                <div className='flex items-center'>
                    <span className='text-accent text-xs'>{formattedTimestamp}</span>
                    <MessageDeliveryTicks message={message} />
                </div>
            </div>}

            {!received && isUploaded !== true && <div className='absolute w-full h-full flex justify-center items-center px-2 download-overlay pointer-events-none'>
                    <svg fill="#000" className='animate animate-spin w-12 h-12 z-50' viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 1.25c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0c7.318 0.001 13.25 5.933 13.25 13.251 0 3.659-1.483 6.972-3.881 9.37v0c-0.14 0.136-0.227 0.327-0.227 0.537 0 0.414 0.336 0.75 0.75 0.75 0.212 0 0.403-0.088 0.539-0.228l0-0c2.668-2.669 4.318-6.356 4.318-10.428 0-8.146-6.604-14.751-14.75-14.751h-0z"></path>
                    </svg>
                </div>}
            {progress !== null && <div className='absolute z-50 w-full h-full flex justify-center items-center px-2 download-overlay'>
                    <span className='text-xs text-accent mr-1'>
                        {progress}%
                    </span>
                    <svg fill="#fff" className='animate animate-spin w-4 h-4' viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 1.25c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0c7.318 0.001 13.25 5.933 13.25 13.251 0 3.659-1.483 6.972-3.881 9.37v0c-0.14 0.136-0.227 0.327-0.227 0.537 0 0.414 0.336 0.75 0.75 0.75 0.212 0 0.403-0.088 0.539-0.228l0-0c2.668-2.669 4.318-6.356 4.318-10.428 0-8.146-6.604-14.751-14.75-14.751h-0z"></path>
                    </svg>
                </div>}
        </div>;
  }, [downloadedData?.url, attachment.thumbnail, initRef, isMainGalleryItem, isGalleryItem, abortControllerId, isDelivered, cancelUpload, activeContact, toggleContactMediaViewer, toggleGroupMediaViewer, formattedTimestamp, message, progress]);
  const renderError = useMemo(() => <div className='shadow flex items-center ml-auto my-1 w-fit rounded-sm pl-2 pr-1 py-2 bg-gray-200'>
            <span className='text-base text-gray-600'>Invalid Photo</span>
            <span className='ml-2 relative -bottom-1 self-end text-gray-400 text-3xs'>{formattedTimestamp}</span>
        </div>, [formattedTimestamp]);
  return renderMessage;
}
export default ImageMessage;