import { current, type PayloadAction } from "@reduxjs/toolkit"
import { HOST, xmpp } from "src/constants/xmpp"
import { getNodeFromJid, sortContactsByLastMessage, sortMessages, setContactsRecords, setContactsClearedAtRecord } from "src/helpers/contact"
import { decryptReceivedMessage, decryptSentMessage, fixTimestamp, parseCallEvent } from "src/helpers/message"
import { type DecryptedMessage, type MessageDeliveryEventData, MessageDeliveryEventType, type DeleteMessageEventData } from "src/types/Message"
import { type AddCallEventParams, type AddDecryptedMessageParams, type AddMessageParams } from "."
import { type ContactsState } from ".."
import { EventBus } from "src/services/EventBus"
import { UupEvents } from "src/constants/events"
import { MessageType } from "src/types/Ejabberd/MessageType"
import moment from "moment"

const reducers = {
    addMessage: (state: ContactsState, action: PayloadAction<AddMessageParams>) => {
        const { data, user } = action.payload;

        if (user === null) return

        const { contactId, message, received } = data
        const contactExists = state.contacts.find(contact => getNodeFromJid(contact.jid) === contactId && contact.isHidden !== true)
        const hiddenContact = state.contacts.find(contact => getNodeFromJid(contact.jid) === contactId && contact.isHidden === true)
        if(hiddenContact !== undefined){
            hiddenContact.isHidden = false
        }
        if (contactExists === undefined && !state.pendingRosters.includes(`${contactId}@${HOST}`)) {            
            state.messages[contactId] = []
            console.log('pending roster', `${contactId}@${HOST}`)
            state.pendingRosters.push(`${contactId}@${HOST}`)
        }

        let existingMessages = state.messages[contactId]
        if (existingMessages === undefined){
            state.messages[contactId] = []
            existingMessages = state.messages[contactId] 
        }
        const isAlreadyReceived = existingMessages.find(existingMessage => existingMessage.id === message.mid)

        if (isAlreadyReceived !== undefined) {
            if (message.ut === undefined) return
            if (isAlreadyReceived.updatedAt !== undefined && message.ut <= isAlreadyReceived.updatedAt) return
            state.messages[contactId] = existingMessages.filter(existingMessage => existingMessage.id !== message.mid)
        }

        let newMessage: DecryptedMessage | null = null

        if (received) {
            const decryptedMessage = decryptReceivedMessage(message, user)
            if (decryptedMessage !== false) newMessage = decryptedMessage
        } else {
            const decryptedMessage = decryptSentMessage(message, user)
            if (decryptedMessage !== false) newMessage = decryptedMessage
        }

        if (newMessage === null) return

        if (state.activeContact !== null && received) {
            const check = getNodeFromJid(state.activeContact.jid) === contactId
            if (check) {
                xmpp.markChatAsRead(state.activeContact.jid)
                setContactsRecords(contactId, newMessage.timestamp, 'readAt')
                newMessage.isRead = true
            }
        }

        const newContactMessages = sortMessages(state, contactId, newMessage)
        state.messages[contactId] = newContactMessages
        EventBus.next({ type: UupEvents.NEW_MESSAGE})
        state.contacts = sortContactsByLastMessage(state.contacts, state.messages)

        // TODO:
        // if (received) {
        //     const notificationAudio = new Audio('/notification.mp3')
        //     notificationAudio.play().catch(console.error)
        // }
    },
    updateMessage: (state: ContactsState, action: PayloadAction<{
        contactId: string;
        message: DecryptedMessage;
    }>) => {
        const { contactId, message } = action.payload;

        state.messages[contactId] = state.messages[contactId].map((existingMessage) => {
            if (existingMessage.id === message.id)
                return message

            return existingMessage
        })

        state.contacts = sortContactsByLastMessage(state.contacts, state.messages)
    },
    updateGroupMessage: (state: ContactsState, action: PayloadAction<{
        groupId: string;
        message: DecryptedMessage;
    }>) => {
        const { groupId, message } = action.payload;

        state.messages[groupId] = state.messages[groupId].map((existingMessage) => {
            if (existingMessage.id === message.id)
                return message

            return existingMessage
        })

        state.contacts = sortContactsByLastMessage(state.contacts, state.messages)
    },
    addCallEvent: (state: ContactsState, action: PayloadAction<AddCallEventParams>) => {
        const { event, user } = action.payload;
        const contactId = event.fid === user?.uid ? event.tid : event.fid

        const messages = state.messages[contactId] ?? []
        const isAlreadyReceived = messages.find(msg => msg.id === event.mid) !== undefined
        const contactNotExists = !(contactId in state.messages)

        if (isAlreadyReceived) {
            console.log(`[${contactId}] Message is already received`, event.t, event.mid)
            return
        }

        if (contactNotExists) {
            state.messages[contactId] = []
            if (contactId !== process.env.REACT_APP_SERVER_UID as string) state.pendingRosters.push(contactId)
        }

        const msg = parseCallEvent(event, event.fid !== user?.uid)
        if (msg === false) return

        const newContactMessages = sortMessages(state, contactId, msg)

        state.messages[contactId] = newContactMessages
        EventBus.next({ type: UupEvents.NEW_MESSAGE })
        state.contacts = sortContactsByLastMessage(state.contacts, state.messages)
    },
    updateMessageDeliveryStatuses(state: ContactsState, action: PayloadAction<string>) {
        const contactId = getNodeFromJid(action.payload)

        const contactMessages = state.messages[contactId]
        if (contactMessages === undefined) return
        let lastSentMessage : DecryptedMessage
        for(let i = contactMessages.slice().length-1; i >= 0; i--){
            if(!contactMessages[i].received){
                lastSentMessage  = contactMessages[i];
                break;
            }
        }

        state.messages[contactId] = contactMessages.map((message, index, messages) => {
            if (!message.received) {
                if (message.isUploaded !== undefined && !message.isUploaded) return message

                const nextReceivedMessage = messages.find((message, _index) => {
                    if (_index > index) return message.received

                    return false
                })

                if (nextReceivedMessage !== undefined) {
                    message.isDelivered = true
                    message.isReceived = true
                    message.isRead = true;
                }
                else {
                    message.isDelivered = true
                }

                return message
            } else {
                if(lastSentMessage === undefined) return message
                if(message.timestamp < lastSentMessage?.timestamp){
                    message.isRead = true
                }
            }

            return message  
        })
    },
    fixDateHeaders: (state: ContactsState, action: PayloadAction<{
        contactId: string;
    }>) => {
        const { contactId } = action.payload;

        const contactMessages = state.messages[contactId]

        if (contactMessages === undefined) return

        const newContactMessages = contactMessages.map((message) => {
            if (message.type === MessageType.DATE_HEADER) return message
            const ts = fixTimestamp(message.timestamp) / 1000
            const id = moment.unix(ts).format('DD-MM-YYYY') + '-date-header'

            return [
                {
                    type: MessageType.DATE_HEADER,
                    timestamp: ts,
                    id,
                    fromId: message.fromId,
                    toId: message.toId,
                    received: false
                },
                message
            ]
        }).flat()
            .filter((message, index, messages) => {
                const previousMessage = messages[index - 1]
                if (previousMessage === undefined) return true
                if (message.type === MessageType.DATE_HEADER && previousMessage.type === MessageType.DATE_HEADER) return false

                return true
            })

        const uniqueMessages = newContactMessages.filter((item, index) => newContactMessages.findIndex(i => i.id === item.id) === index)

        state.messages[contactId] = uniqueMessages
    },
    addDecryptedMessage: (state: ContactsState, action: PayloadAction<AddDecryptedMessageParams>) => {
        const { data, user } = action.payload;

        if (user === null) return

        const { contactId, message } = data

        const existingMessages = state.messages[contactId] ?? []
        const isAlreadyReceived = existingMessages.find(existingMessage => existingMessage.id === message.id) !== undefined
        const contactNotExists = !(contactId in state.messages)

        if (isAlreadyReceived) {
            console.log(`[ALREADY RECEIVED] ${message.id} [${contactId}]`)
            return
        }

        if (contactNotExists) {
            state.messages[contactId] = []
            state.pendingRosters.push(`${contactId}@${HOST}`)
        }

        const newContactMessages = sortMessages(state, contactId, message)
        state.messages[contactId] = newContactMessages
        state.contacts = sortContactsByLastMessage(state.contacts, state.messages)
        EventBus.next({ type: UupEvents.NEW_MESSAGE })
    },
    updateMessageUploadingStatus: (state: ContactsState, action: PayloadAction<{
        contactId: string,
        messageId: string,
        isUploaded: boolean
    }>) => {
        const { contactId, messageId, isUploaded } = action.payload

        const contactMessages = state.messages[contactId]
        if (contactMessages === undefined) return

        const messageIndex = contactMessages.findIndex(message => message.id === messageId)
        if (messageIndex === -1) return

        const message = contactMessages[messageIndex]

        message.isUploaded = isUploaded
        message.isDelivered = true

        state.messages[contactId][messageIndex] = message
    },
    updateMessageUploadProgress: (state: ContactsState, action: PayloadAction<{
        contactId: string,
        messageId: string,
        progress: number
    }>) => {
        const { contactId, messageId, progress } = action.payload
        

        const contactMessages = state.messages[contactId]

        if (contactMessages === undefined) return


        const messageIndex = contactMessages.findIndex(message => message.id === messageId)
        if (messageIndex === -1) return

        const message = contactMessages[messageIndex]

        message.uploadingProgress = progress

        state.messages[contactId][messageIndex] = message
    },
    updateMessageUploadingStatusForGroup: (state: ContactsState, action: PayloadAction<{
        groupId: string,
        messageId: string,
        isUploaded: boolean,
    }>) => {
        const { groupId, messageId, isUploaded } = action.payload

        const groupMessages = state.messages[groupId]
        if (groupMessages === undefined) return

        console.log('groupMessages', messageId, current(groupMessages))

        const messageIndex = groupMessages.findIndex(message => message.id === messageId)
        if (messageIndex === -1) return


        const message = groupMessages[messageIndex]

        message.isUploaded = isUploaded
        message.isDelivered = true

        console.log('message', message)

        state.messages[groupId][messageIndex] = message
    },
    updateMessageDeliveryData: (state: ContactsState, action: PayloadAction<MessageDeliveryEventData>) => {
        const { contactId, messageId, type, isReceivedMessage } = action.payload

        const contactMessages = state.messages[contactId]
        if (contactMessages === undefined) return

        if (type === MessageDeliveryEventType.DELIVERED) {
            const messageIndex = contactMessages.findIndex(message => message.id === messageId)
            if (messageIndex === -1) return

            const message = contactMessages[messageIndex]

            message.isDelivered = true

            state.messages[contactId][messageIndex] = message
        }
        else if (type === MessageDeliveryEventType.RECEIVED) {
            state.messages[contactId] = contactMessages.map(message => {
                if (!message.received)
                     message.isReceived = true
                return message
            })
            setContactsRecords(contactId, Date.now(), 'receivedAt')
        }
        else if (type === MessageDeliveryEventType.READ) {
            state.messages[contactId] = contactMessages.map(message => {
                if (message.isUploaded !== undefined && !message.isUploaded)
                    return message
                if(!message.received) message.isRead = true
                if(isReceivedMessage !== true && message.received) message.isRead = true
                return message
            })
            setContactsRecords(contactId, Date.now(), 'readAt')
        }

    },
    readAllContactMessages: (state: ContactsState, action: PayloadAction<string>) => {
        const contactId = Strophe.getNodeFromJid(action.payload)

        if (!(contactId in state.messages)) return

        state.messages[contactId] = state.messages[contactId]
            .map(message => {
                if (message.isUploaded !== undefined && !message.isUploaded)
                    return message
                if (message.received)
                    message.isRead = true
                return message
            })
    },
    readAllGroupMessages: (state: ContactsState, action: PayloadAction<string>) => {
        const groupId = Strophe.getNodeFromJid(action.payload)

        if (!(groupId in state.messages)) return

        state.messages[groupId] = state.messages[groupId]
            .map(message => {
                if (message.received) message.isRead = true

                return message
            })
    },
    clearMessages: (state: ContactsState, action: PayloadAction<string>) => {
        const contactId = getNodeFromJid(action.payload)

        state.messages[contactId] = []

        const contact = state.contacts.find(contact => getNodeFromJid(contact.jid) === contactId)

        if (contact !== undefined) {
            contact.isClearedAt = Date.now()
            setContactsClearedAtRecord(contactId, Date.now())
        }
    },
    addDeletedMessage(state: ContactsState, action: PayloadAction<DeleteMessageEventData>) {
        const { jid, messageId, hide } = action.payload;
        const id = getNodeFromJid(jid);

        if (!(id in state.deletedMessages))
            state.deletedMessages[id] = [];

        if (!(id in state.messages))
            state.messages[id] = [];

        if (hide === true) {
            state.messages[id] = state.messages[id].map(message => {
                if (message.id === messageId) message.isDeleted = true;
                return message;
            })

            state.deletedMessages[id].push(messageId)
        }

        else state.deletedMessages[id].push(messageId)
    },
    deleteMessage(state: ContactsState, action: PayloadAction<{
        contactId: string;
        messageId: string;
    }>) {
        const { contactId, messageId } = action.payload;

        state.messages[contactId] = state.messages[contactId].filter(message => message.id !== messageId);
    },
    deleteGroupMessage(state: ContactsState, action: PayloadAction<{
        groupId: string;
        messageId: string;
    }>) {
        const { groupId, messageId } = action.payload;

        state.messages[groupId] = state.messages[groupId].filter(message => message.id !== messageId);
    },
    removeDeletedMessage(state: ContactsState, action: PayloadAction<DeleteMessageEventData>) {
        const { jid, messageId } = action.payload;
        const id = getNodeFromJid(jid);

        if (!(id in state.deletedMessages)) {
            state.deletedMessages[id] = [];
        }

        state.deletedMessages[id] = state.deletedMessages[id].filter((id: string) => id !== messageId);
    },
}

export default reducers
