import { useEffect, type ChangeEvent } from 'react';
import type React from 'react';
import { useCallback, useMemo, useState, useRef } from 'react';
import { GROUP_HOST, xmpp } from 'src/constants/xmpp';
import { getNodeFromJid } from 'src/helpers/contact';
import { useAppDispatch, useContacts, useConversation, useUser } from 'src/hooks/store';
import { SearchIndex } from 'emoji-mart';
import AudioRecorder, { type RecordingData } from '../../AudioRecorder';
import { generateTimestamp, replaceWordWithEmoji, saveFileToDisk } from 'src/helpers/message';
import { MessageType } from 'src/types/Ejabberd/MessageType';
import UploadFilePreview from 'src/components/UploadFilePreview';
import { calculateFileSize, prepareFileMessageForGroup, uploadDocument } from 'src/helpers/file';
import { prepareAudioMessageForGroup, uploadAudio } from 'src/helpers/audio';
import { generateThumbnail, prepareGifMessageForGroup, prepareImageMessageForGroup, uploadImage } from 'src/helpers/image';
import { cancelGroupEditing, cancelGroupReplying, resetConversationState, updateGroupEditingMessage } from 'src/store/slices/conversation';
import EmojiPicker from 'src/components/EmojiPicker';
import OutsideClickDetector from 'src/components/OutsideClickDetector';
import { type FILE_PICKER_MODE, FILE_PICKER_MODES } from 'src/constants/files';
import FoundEmojisPicker from 'src/components/EmojiPicker/FoundEmojis';
import UploadMenu from 'src/components/UploadMenu';
import { useObjectState } from 'src/hooks/useObjectState';
import uupStorage from 'src/contexts/DB';
import clsx from 'clsx';
import { useEmoji } from 'src/contexts/Emoji';
import { blobToBase64, getVideoCover, getVideoDuration, prepareVideoMessageForGroup, uploadVideo } from 'src/helpers/video';
import { v4 as uuidv4 } from 'uuid';
import { deleteGroupMessage, updateGroupMessage, updateMessageUploadingStatusForGroup } from 'src/store/slices/contacts';
import { type MessageAdditionalData } from 'src/types/Message';
import { onEvent } from 'src/services/EventBus';
import { UupEvents } from 'src/constants/events';
import { type GiphyData } from 'src/types/giphy';
import { getTrendingGifs, searchGifs } from 'src/helpers/giphy';
import { debounce } from 'lodash';
import { generateEncryptedMessageContentForGroup, insertGroupMessage } from 'src/helpers/group';
import { capitalizeFirstLetter } from 'src/helpers/utils';
interface EmojiState {
  show: boolean;
  found: string[];
  selected: number | null;
}
interface FilePickerOptions {
  type: FILE_PICKER_MODE | null;
  accept: string;
}
function GroupChatActions(): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    user
  } = useUser();
  const {
    activeGroup,
    contacts,
    threadMessageId,
    activeChatSettings
  } = useContacts();
  const {
    groupEditing,
    groupReplying
  } = useConversation();
  const emojiData = useEmoji();
  const messageRef = useRef<HTMLInputElement>(null);
  const [showUploadMenu, $showUploadMenu] = useState<boolean>(false);
  const [abortControllers, $abortControllers] = useState<Record<string, AbortController>>({});
  const [showGifPicker, $showGifPicker] = useState<boolean>(false);
  const [gifSearch, $gifSearch] = useState<string>('');
  const [foundGifs, $foundGifs] = useState<GiphyData[]>([]);
  const filePickerRef = useRef<HTMLInputElement>(null);
  const [filePicker, $filePicker] = useState<FilePickerOptions>({
    type: null,
    accept: ''
  });
  const [message, $message] = useState<string>('');
  const [emojis, $emojis] = useObjectState<EmojiState>({
    show: false,
    found: [],
    selected: null
  });
  const [caretPosition, $caretPosition] = useState<number>(0);
  const [file, $file] = useState<File | null>(null);
  const [isRecording, $isRecording] = useState<boolean>(false);
  const typingTimeout = useRef<NodeJS.Timeout | null>(null);
  const searchEmoji = useCallback(async (value: string): Promise<string[]> => {
    const emojis = await SearchIndex.search(value);
    return emojis.map((emoji: any) => emoji.skins[0].native);
  }, []);
  const onChangeMessage = useCallback(async (event: React.FormEvent<HTMLInputElement>) => {
    const {
      currentTarget
    } = event;
    $message(currentTarget.value);
    $caretPosition(currentTarget.selectionStart ?? 0);
    const lastWord = currentTarget.value.split(' ').filter((word: string) => word.trim().length > 0).pop();
    if (typeof lastWord === 'string') {
      const searchStr = lastWord.replace(/( )/gi, '');
      const emojis: string[] = await searchEmoji(searchStr);
      $emojis({
        show: false,
        found: emojis.slice(0, 5)
      });
    } else {
      $emojis({
        found: []
      });
    }
  }, [$emojis, searchEmoji]);
  const sendTextMessage = useCallback(async () => {
    if (!window.navigator.onLine || xmpp.connection === undefined) return;
    if (activeGroup === null) return;
    if (user === null) return;
    const encryptedMessage = generateEncryptedMessageContentForGroup(MessageType.TEXT, message, activeGroup, user, {
      qid: groupReplying?.message.id,
      thid: threadMessageId === null ? undefined : threadMessageId
    });
    if (encryptedMessage === false) return;
    console.log('SENDING MESSAGE', encryptedMessage);
    insertGroupMessage(encryptedMessage, user, dispatch);
    xmpp.sendGroupMessage(activeGroup.jid, encryptedMessage);
    $message('');
    dispatch(resetConversationState());
    $emojis({
      found: [],
      selected: null
    });
  }, [$emojis, activeGroup, dispatch, groupReplying?.message.id, message, threadMessageId, user]);
  const sendAudioRecording = useCallback(async (recordingData: RecordingData) => {
    if (!window.navigator.onLine || xmpp.connection === undefined) return;
    if (user === null || activeGroup === null) return;
    const filename = uuidv4() + '.wav';
    const audioMessage = await prepareAudioMessageForGroup(filename, activeGroup, recordingData, user, {
      qid: groupReplying?.message.id,
      thid: threadMessageId === null ? undefined : threadMessageId
    });
    if (audioMessage === false) return;
    uploadAudio(getNodeFromJid(activeGroup.jid), audioMessage.mid, filename, recordingData, dispatch).catch(console.error);
    await uupStorage.setItem(`attachment-${audioMessage.mid}`, recordingData.blob);
    insertGroupMessage(audioMessage, user, dispatch);
    xmpp.sendGroupMessage(activeGroup.jid, audioMessage);
    $isRecording(false);
    dispatch(resetConversationState());
  }, [activeGroup, dispatch, groupReplying?.message.id, threadMessageId, user]);
  const onSelectEmoji = useCallback((event: any) => {
    $message(prev => `${prev}${(event.native as string)}`);
    $emojis({
      found: [],
      selected: null
    });
  }, [$emojis]);
  useEffect(() => {
    const subscribe = onEvent(UupEvents.CANCEL_UPLOAD, ({
      abortControllerId,
      messageId
    }) => {
      console.log('ABORTING UPLOAD', abortControllerId, abortControllers, abortControllers[abortControllerId]);
      if (activeGroup === null) return;
      abortControllers[abortControllerId]?.abort();
      $abortControllers(prev => {
        delete prev[abortControllerId];
        return prev;
      });
      dispatch(deleteGroupMessage({
        groupId: getNodeFromJid(activeGroup.jid),
        messageId
      }));
    });
    return () => {
      subscribe.unsubscribe();
    };
  }, [abortControllers, dispatch, activeGroup]);
  const debouncedSearchGif = debounce((value: string) => {
    if (gifSearch.length >= 2) {
      searchGifs(gifSearch).then(gifs => {
        $foundGifs(gifs.data);
      }).catch(console.error);
    }
  }, 500);
  useEffect(() => {
    getTrendingGifs().then(gifs => {
      $foundGifs(gifs.data);
    }).catch(console.error);
  }, []);
  useEffect(() => {
    debouncedSearchGif(gifSearch);
  }, [gifSearch]);
  const sendImage = useCallback(async (editedImage: File) => {
    if (!window.navigator.onLine || xmpp.connection === undefined || file === null) return;
    const [data, imageWidth, imageHeight] = await generateThumbnail(editedImage, [100, 100]);
    const thumbnail = data.replace('data:image/jpeg;base64,', '');
    const fileSize = calculateFileSize(editedImage);
    const additionalData: MessageAdditionalData = {};
    const extension = file.name.split('.').pop()?.toLowerCase() ?? '.jpg';
    if (groupReplying !== null) additionalData.qid = groupReplying.message.id;
    if (threadMessageId !== null) additionalData.thid = threadMessageId;
    if (user === null || activeGroup === null) return;
    const filename = uuidv4() + `.${extension}`;
    const imageMessage = await prepareImageMessageForGroup(activeGroup, filename, thumbnail, fileSize, imageWidth, imageHeight, user, additionalData);
    if (imageMessage === false) {
      console.error('Error preparing image message');
      return;
    }
    imageMessage.isDelivered = false;
    imageMessage.isRead = false;
    const controllerId = uuidv4();
    const controller = new AbortController();
    $abortControllers(prev => ({
      ...prev,
      [controllerId]: controller
    }));
    await saveFileToDisk(imageMessage.mid, editedImage);
    uploadImage(getNodeFromJid(activeGroup.jid), imageMessage.mid, editedImage, filename, controller, dispatch).then((filename: string | false) => {
      console.log('IMAGE UPLOADED', filename);
      dispatch(updateMessageUploadingStatusForGroup({
        groupId: getNodeFromJid(activeGroup.jid),
        messageId: imageMessage.mid,
        isUploaded: true
      }));
      $abortControllers(prev => {
        delete prev[controllerId];
        return prev;
      });
      xmpp.sendGroupMessage(activeGroup.jid, imageMessage);
    }).catch(console.error);
    insertGroupMessage(imageMessage, user, dispatch, {
      isUploaded: false,
      abortControllerId: controllerId
    });
    dispatch(resetConversationState());
    $file(null);
  }, [activeGroup, dispatch, file, groupReplying, threadMessageId, user]);
  const sendGif = useCallback(async (gifData: GiphyData) => {
    if (!window.navigator.onLine || xmpp.connection === undefined) return;
    const additionalData: MessageAdditionalData = {};
    if (groupReplying !== null) additionalData.qid = groupReplying.message.id;
    if (threadMessageId !== null) additionalData.thid = threadMessageId;
    if (user === null || activeGroup === null) return;
    const gifMessage = await prepareGifMessageForGroup(activeGroup, gifData, user, additionalData);
    if (gifMessage === false) {
      console.error('Error preparing image message');
      return;
    }
    gifMessage.isDelivered = false;
    gifMessage.isRead = false;

    // await saveFileToDisk(gifMessage.mid, editedImage)
    xmpp.sendGroupMessage(activeGroup.jid, gifMessage);
    insertGroupMessage(gifMessage, user, dispatch, {
      // isUploaded: false,
      // abortControllerId: controllerId
    });
    dispatch(resetConversationState());
    $showGifPicker(false);
    $gifSearch('');
    getTrendingGifs().then(gifs => {
      $foundGifs(gifs.data);
    }).catch(console.error);
  }, [activeGroup, dispatch, groupReplying, threadMessageId, user]);
  const sendVideo = useCallback(async (file: File) => {
    if (!window.navigator.onLine || xmpp.connection === undefined) return;
    console.log('Sending video', file);
    const thumbnailBlob = await getVideoCover(file);
    if (thumbnailBlob === null) return;
    let thumbnailBase64 = await blobToBase64(thumbnailBlob);
    if (thumbnailBase64 === null || typeof thumbnailBase64 !== 'string') return;
    thumbnailBase64 = thumbnailBase64.replace('data:image/jpeg;base64,', '');
    const fileSize = calculateFileSize(file);
    const additionalData: MessageAdditionalData = {};
    console.log('fileSize', fileSize);
    if (groupReplying !== null) additionalData.qid = groupReplying.message.id;
    if (threadMessageId !== null) additionalData.thid = threadMessageId;
    if (user === null || activeGroup === null) return;
    const filename = uuidv4() + `.mp4`;
    const duration = await getVideoDuration(file);
    const videoMessage = await prepareVideoMessageForGroup(activeGroup, duration, thumbnailBase64, filename, fileSize, user, additionalData);
    if (videoMessage === false) {
      console.error('Error preparing image message');
      return;
    }
    videoMessage.isDelivered = false;
    videoMessage.isRead = false;
    const controllerId = uuidv4();
    const controller = new AbortController();
    $abortControllers(prev => ({
      ...prev,
      [controllerId]: controller
    }));
    await saveFileToDisk(videoMessage.mid, file);
    uploadVideo(getNodeFromJid(activeGroup.jid), videoMessage.mid, file, filename, controller, dispatch).then((filename: string | false) => {
      console.log('VIDEO UPLOADED', filename, videoMessage.mid);
      dispatch(updateMessageUploadingStatusForGroup({
        groupId: getNodeFromJid(activeGroup.jid),
        messageId: videoMessage.mid,
        isUploaded: true
      }));
      $abortControllers(prev => {
        delete prev[controllerId];
        return prev;
      });
      xmpp.sendGroupMessage(activeGroup.jid, videoMessage);
    }).catch(console.error);
    insertGroupMessage(videoMessage, user, dispatch, {
      isUploaded: false,
      abortControllerId: controllerId
    });
    dispatch(resetConversationState());
    $file(null);
  }, [activeGroup, dispatch, groupReplying, threadMessageId, user]);
  const sendDocument = useCallback(async (file: File) => {
    if (!window.navigator.onLine || xmpp.connection === undefined) return;
    console.log('Sending document', file);
    const fileSize = calculateFileSize(file);
    const extension = file.name.split('.').pop() ?? '';
    const additionalData: MessageAdditionalData = {
      filename: file.name
    };
    if (groupReplying !== null) additionalData.qid = groupReplying.message.id;
    if (threadMessageId !== null) additionalData.thid = threadMessageId;
    if (user === null || activeGroup === null) return;
    const filename = uuidv4() + `.${extension}`;
    const fileMessage = await prepareFileMessageForGroup(activeGroup, file.name, filename, fileSize, user, additionalData);
    if (fileMessage === false) {
      console.error('Error preparing file message');
      return;
    }
    fileMessage.isDelivered = false;
    fileMessage.isRead = false;
    const controllerId = uuidv4();
    const controller = new AbortController();
    $abortControllers(prev => ({
      ...prev,
      [controllerId]: controller
    }));
    await saveFileToDisk(fileMessage.mid, file);
    uploadDocument(getNodeFromJid(activeGroup.jid), fileMessage.mid, file, filename, controller, dispatch).then((filename: string | false) => {
      console.log('DOCUMENT UPLOADED', filename);
      dispatch(updateMessageUploadingStatusForGroup({
        groupId: getNodeFromJid(activeGroup.jid),
        messageId: fileMessage.mid,
        isUploaded: true
      }));
      $abortControllers(prev => {
        delete prev[controllerId];
        return prev;
      });
      xmpp.sendGroupMessage(activeGroup.jid, fileMessage);
    }).catch(console.error);
    insertGroupMessage(fileMessage, user, dispatch, {
      isUploaded: false,
      abortControllerId: controllerId
    });
    dispatch(resetConversationState());
    $file(null);
  }, [activeGroup, dispatch, groupReplying, threadMessageId, user]);
  const onPressFoundEmoji = useCallback((e: any) => {
    $message(prev => replaceWordWithEmoji(prev, (e.native as string) ?? e));
    $emojis({
      found: [],
      selected: null
    });
  }, [$emojis]);
  const handleEmojiNavigation = useCallback((event: React.KeyboardEvent) => {
    const {
      selected
    } = emojis;
    if (event.key === 'ArrowDown') $emojis({
      found: [],
      selected: null
    });else if (event.key === 'ArrowUp') $emojis({
      selected: 0
    });else if (event.key === 'ArrowLeft') {
      const newSelection = selected !== null && selected !== 0 ? selected - 1 : -1;
      $emojis({
        selected: newSelection
      });
    } else if (event.key === 'ArrowRight') {
      const newSelection = selected !== null && selected !== emojis.found.length - 1 ? selected + 1 : null;
      $emojis({
        selected: newSelection
      });
    } else if (event.key === 'Backspace') $emojis({
      found: [],
      selected: null
    });
  }, [$emojis, emojis]);
  const onSelectFoundEmoji = useCallback((index: number) => {
    $message(prev => replaceWordWithEmoji(prev, emojis.found[index]));
    $emojis({
      found: [],
      selected: null
    });
  }, [$emojis, emojis]);
  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (emojis.selected !== null) onSelectFoundEmoji(emojis.selected);else if (message.length > 0) sendTextMessage().catch(console.error);
    } else if (emojis.found.length > 0) handleEmojiNavigation(event);
  }, [emojis.found.length, emojis.selected, handleEmojiNavigation, message.length, onSelectFoundEmoji, sendTextMessage]);
  const toggleEmojiPicker = useCallback(() => {
    $emojis({
      show: !emojis.show
    });
    $showUploadMenu(false);
  }, [$emojis, emojis.show]);
  const renderEmojiPicker = useMemo(() => <OutsideClickDetector onClickOutside={toggleEmojiPicker} className='absolute bottom-20 left-6 z-50'>
            <EmojiPicker data={emojiData} onEmojiSelect={onSelectEmoji} maxFrequentRows={1} theme='auto' />
        </OutsideClickDetector>, [emojiData, onSelectEmoji, toggleEmojiPicker]);
  const cancelReply = useCallback(() => {
    dispatch(cancelGroupReplying());
  }, [dispatch]);
  const renderReplyingMessage = useMemo(() => {
    if (groupReplying === null) return null;
    const username = groupReplying.message.fromId === user?.uid ? 'Me' : groupReplying.contact.name;
    return <div className='absolute bg-primary text-accent text-xs w-full bottom-24 p-2 pb-3 left-0 z-50'>

            <div className='border-l-4 border-l-primary-light p-1 bg-accent text-primary flex justify-between items-start'>
                <div className="flex flex-col">
                    <span className='font-semibold italic'>{username}</span>
                    <span className='text-primary-light'>{groupReplying.message.content ?? capitalizeFirstLetter(groupReplying.message.type)}</span>
                </div>
                <button onClick={cancelReply} className='w-4 h-4 bg-primary rounded-sm flex items-center justify-center transition hover:opacity-80'>
                    <i className="bi bi-x text-accent"></i>
                </button>
            </div>

        </div>;
  }, [cancelReply, groupReplying, user?.uid]);
  const onChangeEditingMessage = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (groupEditing === null) return;
    const newEditingMessage = {
      ...groupEditing.message,
      content: e.target.value
    };
    dispatch(updateGroupEditingMessage(newEditingMessage));
  }, [dispatch, groupEditing]);
  const cancelEdit = useCallback(() => {
    dispatch(cancelGroupEditing());
  }, [dispatch]);
  const saveEditedMessage = useCallback(async () => {
    if (activeGroup === null || groupEditing === null || groupEditing.message.content === undefined) return;
    if (!window.navigator.onLine || xmpp.connection === undefined) return;
    const newMessage = {
      ...groupEditing.message,
      content: groupEditing.message.content
    };
    if (newMessage.content?.length === 0) {
      alert('Message cannot be empty');
    }
    if (user === null) return;
    const timestamp = generateTimestamp();
    const newEditingMessage = {
      ...groupEditing.message,
      updatedAt: timestamp
    };
    dispatch(updateGroupEditingMessage(newEditingMessage));
    const encryptedMessage = generateEncryptedMessageContentForGroup(MessageType.TEXT, newEditingMessage.content ?? "", activeGroup, user, {
      mid: newEditingMessage.id,
      ut: timestamp
    });
    if (encryptedMessage === false) return;
    xmpp.correctGroupMessage(activeGroup.jid, newEditingMessage.id, encryptedMessage);
    dispatch(updateGroupMessage({
      groupId: activeGroup.jid,
      message: newEditingMessage
    }));
    dispatch(cancelGroupEditing());
  }, [activeGroup, dispatch, groupEditing, user]);
  const renderEditingMessage = useMemo(() => {
    if (groupEditing === null) return null;
    return <div className='absolute bg-primary text-accent text-xs w-full bottom-0 p-2 pb-3 left-0 z-50'>
            <div className='border-l-4 border-l-primary-light p-1 bg-accent text-primary flex justify-between items-start'>
                <div className="w-full flex flex-col">
                    <span className='font-semibold italic'>Editing</span>
                    <input value={groupEditing.message.content} onChange={onChangeEditingMessage} className='bg-gray-200 text-primary text-xs w-full mt-1 p-2 rounded outline-none' />
                </div>
                <div className="flex items-center ml-4">
                    <button onClick={cancelEdit} className='w-6 h-6 mx-1 bg-status-offline rounded-sm flex items-center justify-center transition hover:opacity-80'>
                        <i className="bi bi-x text-accent text-lg"></i>
                    </button>
                    <button onClick={saveEditedMessage} className='w-6 h-6 bg-primary rounded-sm flex items-center justify-center transition hover:opacity-80'>
                        <i className="bi bi-check text-accent text-lg"></i>
                    </button>
                </div>
            </div>
        </div>;
  }, [cancelEdit, groupEditing, onChangeEditingMessage, saveEditedMessage]);
  const toggleUploadMenu = useCallback(() => {
    $showUploadMenu(prev => !prev);
    $emojis({
      show: false
    });
  }, [$emojis]);
  const pickFile = useCallback((type: FILE_PICKER_MODE) => {
    if (type === FILE_PICKER_MODES.GIF) {
      $showGifPicker(true);
      $showUploadMenu(false);
      return;
    }
    $filePicker({
      type,
      accept: `${type}/*`
    });
    requestAnimationFrame(() => {
      if (filePickerRef.current === null) return;
      filePickerRef.current.click();
    });
  }, []);
  const onChangeFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const {
      files
    } = event.target;
    if (files === null || files.length === 0) return;
    $file(files[0]);
    $showUploadMenu(false);
    event.target.value = '';
  }, []);
  const onSendFile = useCallback((file: File) => {
    if (file.type.includes('video')) sendVideo(file).catch(console.error);else if (file.type.includes('image')) sendImage(file).catch(console.error);else sendDocument(file).catch(console.error);
  }, [sendDocument, sendImage, sendVideo]);
  const renderGifPicker = useMemo(() => {
    if (!showGifPicker) return null;
    return <div className='absolute bg-primary text-accent text-xs w-full bottom-24 p-2 pb-3 left-0 z-50'>
            <div className='flex justify-between items-center'>
                <input value={gifSearch} onChange={({
          target
        }) => {
          $gifSearch(target.value);
        }} className='bg-gray-200 text-primary text-xs w-full p-2 rounded outline-none' placeholder='Search GIF...' />
                <div className="flex items-center ml-4">
                    <button onClick={() => {
            $showGifPicker(false);
          }} className='w-6 h-6 mx-1 bg-status-offline rounded-sm flex items-center justify-center transition hover:opacity-80'>
                        <i className="bi bi-x text-accent text-lg"></i>
                    </button>
                </div>
            </div>
            {foundGifs.length > 0 && <div className='flex flex-wrap justify-center mt-2 h-96 overflow-y-scroll'>
                    {foundGifs.map(gif => <button key={gif.id} onClick={() => {
          sendGif(gif).catch(console.error);
        }} className='w-[calc(33%_-_16px)] h-24 m-2 rounded-sm overflow-hidden'>
                        <img src={gif.images.fixed_width.url} alt={gif.title} className='w-full h-full object-cover' />
                    </button>)}
                </div>}
        </div>;
  }, [foundGifs, gifSearch, sendGif, showGifPicker]);
  return <>
            <div>
                <input ref={filePickerRef} type="file" accept={filePicker.accept} className="hidden" onChange={onChangeFile} />
                {file !== null && <UploadFilePreview file={file} onClose={() => {
        $file(null);
      }} onSend={onSendFile} />}
                {renderReplyingMessage}
                {renderEditingMessage}
                {renderGifPicker}

                <div className={clsx('absolute flex items-center z-50 bottom-0 w-full h-24 bg-chat-bottom-bg p-6', groupEditing !== null && 'hidden')}>
                    {emojis.show && renderEmojiPicker}
                    <FoundEmojisPicker emojis={emojis} caretPosition={caretPosition} onPressFoundEmoji={onPressFoundEmoji} />

                    <button onClick={toggleEmojiPicker} data-trigger className={clsx('mr-6 transition hover:opacity-80', emojis.show && 'text-light-blue', isRecording && 'hidden')}>
                        <i className="bi bi-emoji-smile text-2xl"></i>
                    </button>

                    <div className={clsx('relative mr-6 center-flex', isRecording && 'hidden')}>
                        <button data-trigger className='group transition text-white' onClick={toggleUploadMenu}>
                            <svg className="w-6 h-6" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className='transition group-hover:stroke-light-blue' d="M10.9996 7.32715V14.6535" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path className='transition group-hover:stroke-light-blue' d="M14.6663 10.9904H7.33301" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path className='transition group-hover:stroke-light-blue' fillRule="evenodd" clipRule="evenodd" d="M15.6857 1H6.31429C3.04762 1 1 3.31208 1 6.58516V15.4148C1 18.6879 3.0381 21 6.31429 21H15.6857C18.9619 21 21 18.6879 21 15.4148V6.58516C21 3.31208 18.9619 1 15.6857 1Z" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>

                        {showUploadMenu && <UploadMenu onClick={pickFile} onClickOutside={toggleUploadMenu} />}
                    </div>

                    <input ref={messageRef} value={message} onChange={onChangeMessage} onKeyDown={onKeyDown} className={clsx('w-full px-6 py-3 text-sm text-type-message-text rounded-3xl', isRecording && 'hidden')} type="text" placeholder='Say Something...' />

                    {message.length > 0 ? <button onClick={sendTextMessage} className='ml-4 transition hover:opacity-80'>
                            <i className="bi bi-send text-primary text-lg"></i>
                        </button> : <AudioRecorder onRecordStatusChange={(value: boolean) => {
          $isRecording(value);
        }} onPressSend={sendAudioRecording} />}
                </div>
            </div>
        </>;
}
export default GroupChatActions;