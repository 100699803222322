import { type EncryptedGroupMessage, type EncryptedMessage } from "src/types/Ejabberd/Message"
import { type Contact } from "src/types/Contact"
import { type User } from "src/types/User"
import { encryptAES, generateAes, generateEncryptedMessageContent, generateIv } from "./message"
import { MessageType } from "src/types/Ejabberd/MessageType"
import { getNodeFromJid } from "./contact"
import { getAWSSignedUrl } from "./file"
import axios from "axios"
import { generateEncryptedMessageContentForGroup } from "./group"
import { updateMessageUploadProgress } from "src/store/slices/contacts"
import type { AppDispatch } from "src/store"


export const uploadVideo = async (contactId: string, messageId: string, blob: Blob, filename: string, controller: AbortController, dispatch: AppDispatch): Promise<string | false> => {
    const path = `media/videos/${filename}`
    const signedData = await getAWSSignedUrl(path, 'PUT_OBJECT')

    if (signedData === false) return false

    const upload = await axios.put(signedData.url, blob, {
        signal: controller.signal,
        onUploadProgress: (evt) => {
            const progress =  Math.round((evt.loaded / evt.total) * 100)
            dispatch(updateMessageUploadProgress({
                contactId,
                messageId,
                progress
            }))
        }
    })

    if (upload.status !== 200) return false

    return filename
}

export async function prepareVideoMessageForContact(
    contact: Contact,
    duration: number,
    thumbnail: string,
    filename: string,
    filesize: string,
    userData: User,
    additional: object = {}
): Promise<EncryptedMessage | false> {
    const aes = generateAes()
    const iv = generateIv()
    const encryptedVideoPath = encryptAES(filename, aes, iv)
    const myEncryptedVideoPath = encryptAES(filename, userData.aes, userData.iv)

    const attachment = {
        dt: encryptedVideoPath,
        mdt: myEncryptedVideoPath,
        tb: thumbnail,
        md: JSON.stringify({
            s: filesize,
            d: duration.toFixed(1).toString()
        })
    }

    return generateEncryptedMessageContent(
        MessageType.VIDEO,
        JSON.stringify(attachment),
        getNodeFromJid(contact.jid),
        contact.key, aes, iv, userData, additional
    )
}

export async function prepareVideoMessageForGroup(
    group: Contact,
    duration: number,
    thumbnail: string,
    filename: string,
    filesize: string,
    userData: User,
    additional: object = {}
): Promise<EncryptedGroupMessage | false> {
    const [iv, aes] = group.key.split(',')
    const encryptedVideoPath = encryptAES(filename, aes, iv)
    const encryptedMyVideoPath = encryptAES(filename, userData.aes, userData.iv)

    const attachment = {
        dt: encryptedVideoPath,
        mdt: encryptedMyVideoPath,
        tb: thumbnail,
        md: JSON.stringify({
            s: filesize,
            d: duration.toString()
        })
    }

    return generateEncryptedMessageContentForGroup(
        MessageType.VIDEO,
        JSON.stringify(attachment),
        group,
        userData, additional
    )
}

export async function getVideoDuration(file: File): Promise<number> {
    return await new Promise((resolve, reject) => {
        const video = document.createElement('video')
        video.preload = 'metadata'

        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src)
            resolve(video.duration)
        }

        video.onerror = function () {
            reject(new Error('Error parsing video'))
        }

        video.src = URL.createObjectURL(file)
    })
}


export async function getVideoCover(file: File | Blob, seekTo: number = 0.0): Promise<Blob | null> {
    console.log("getting video cover for file: ", file);
    return await new Promise((resolve, reject) => {
        // load the file to a video player
        const videoPlayer = document.createElement('video');
        videoPlayer.setAttribute('src', URL.createObjectURL(file));
        videoPlayer.load();
        videoPlayer.addEventListener('error', (ex) => {
            reject(new Error("error when loading video file"))
        });
        // load metadata of the video to get video duration and dimensions
        videoPlayer.addEventListener('loadedmetadata', () => {
            // seek to user defined timestamp (in seconds) if possible
            if (videoPlayer.duration < seekTo) {
                reject(new Error("video is too short."));
                return;
            }
            // delay seeking or else 'seeked' event won't fire on Safari
            setTimeout(() => {
                videoPlayer.currentTime = seekTo;
            }, 200);
            // extract video thumbnail once seeking is complete
            videoPlayer.addEventListener('seeked', () => {
                console.log('video is now paused at %ss.', seekTo);
                // define a canvas to have the same dimension as the video
                const canvas = document.createElement("canvas");
                canvas.width = videoPlayer.videoWidth;
                canvas.height = videoPlayer.videoHeight;
                // draw the video frame to canvas
                const ctx = canvas.getContext("2d");
                if (ctx === null) {
                    reject(new Error("cannot get canvas context"));
                    return;
                }
                ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                // return the canvas image as a blob
                ctx.canvas.toBlob(
                    blob => {
                        resolve(blob);
                    },
                    "image/jpeg",
                    0.75 /* quality */
                );
            });
        });
    });
}

export async function blobToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {
    return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => { resolve(reader.result); }
        reader.readAsDataURL(blob);
    });
}
