import moment from 'moment';
import { useMemo, useCallback, useState } from 'react';
import { parseTimestamp, saveFileToDisk } from 'src/helpers/message';
import { type FileAttachment, type DecryptedMessage } from 'src/types/Message';
import uupStorage from 'src/contexts/DB';
import { getAWSSignedUrl } from 'src/helpers/file';
import axios from 'axios';
import MessageDeliveryTicks from 'src/components/UI/MessageDeliveryTicks';
import clsx from 'clsx';
import { saveAs } from 'file-saver';
import { EventBus } from 'src/services/EventBus';
import { UupEvents } from 'src/constants/events';
interface Props {
  message: DecryptedMessage;
}
interface DownloadedData {
  blob: Blob;
}
function FileMessage({
  message
}: Props): JSX.Element | null {
  const {
    timestamp,
    received,
    abortControllerId,
    isDelivered
    // isReceived,
    // isRead
  } = message;
  const {
    isUploaded
  } = message;
  const attachment = (message.attachment as FileAttachment);
  const [downloadedData, $downloadedData] = useState<DownloadedData | null>(null);
  const formattedTimestamp: string = moment.unix(parseTimestamp(timestamp)).format('HH:mm');
  const [error, $error] = useState<boolean>(false);
  const [progress, $progress] = useState<number | null>(null);
  const fullPath = `media/files/${attachment.data}`;
  const getFileExtension = useCallback(() => {
    return attachment.data.split('.').pop() ?? '';
  }, [attachment.data]);
  const getStoredFile = useCallback(async (): Promise<DownloadedData | null> => {
    try {
      console.log('[FILE MESSAGE] Get stored file');
      const attachmentId = message.oldId ?? message.id;
      const blob = (await uupStorage.getItem(`attachment-${attachmentId}`) as Blob);
      if (blob === null) return null;
      return {
        blob
      };
    } catch (error) {
      console.error('[FILE MESSAGE] Get stored file', error);
      return null;
    }
  }, [message.id, message.oldId]);
  const fileType = useMemo(() => {
    const type = downloadedData?.blob.type;
    if (type === 'image/jpeg') return "JPG";else if (type === 'image/png') return "PNG";else if (type === 'video/mp4') return "MP4";else if (type === 'application/pdf') return "PDF";else if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') return "DOCX";else if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') return "XLSX";else if (type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') return "PPTX";else return "octet-stream";
  }, [downloadedData?.blob.type]);
  const downloadWithSignedURL = useCallback(async () => {
    try {
      $progress(0);
      console.log('[FILE MESSAGE] Get signed url', fullPath);
      const signedData = await getAWSSignedUrl(fullPath, 'GET_OBJECT');
      if (signedData === false) return;
      const download = await axios.get(signedData.url, {
        responseType: 'arraybuffer',
        onDownloadProgress: progressEvent => {
          if (progressEvent.lengthComputable === false) return;

          // const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
          // const current = progressEvent.currentTarget.response.length
          const total = parseFloat(progressEvent.total);
          const current = progressEvent.loaded;
          const percentCompleted = Math.round(current / total * 100);
          $progress(percentCompleted);
        }
      });
      const streamContent = (download.data as Blob);
      const extension = getFileExtension();
      console.log('[FILE MESSAGE] Downloaded', streamContent, extension);
      const blob = new Blob([streamContent], {
        type: 'application/' + extension
      });
      saveFileToDisk(message.id, blob).catch(console.error);
      $downloadedData({
        blob
      });
    } catch (error) {
      console.error('[FILE MESSAGE] Get signed url', error);
      $error(true);
    } finally {
      $progress(null);
    }
  }, [fullPath, getFileExtension, message]);
  const handleEntry = useCallback(async (entry: IntersectionObserverEntry) => {
    if (!entry.isIntersecting) return;
    // if (!received && isUploaded !== true) return
    const storedFile = await getStoredFile();
    if (storedFile !== null) {
      $downloadedData(storedFile);
      return;
    }
    downloadWithSignedURL().catch(console.error);
  }, [getStoredFile, downloadWithSignedURL]);
  const intersectionCallback = useCallback((entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      void handleEntry(entry);
    });
  }, [handleEntry]);
  const download = useCallback(() => {
    console.log('[FILE MESSAGE] Download', downloadedData);
    if (downloadedData === null) return;
    saveAs(downloadedData.blob, attachment.metaData.name);
  }, [attachment.metaData.name, downloadedData]);
  const initRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const observer = new IntersectionObserver(intersectionCallback, {
        root: null,
        rootMargin: "0px",
        threshold: 0.4
      });
      observer.observe(node);
    }
  }, [intersectionCallback]);
  const cancelUpload = useCallback(() => {
    if (abortControllerId === null) return;
    EventBus.next({
      type: UupEvents.CANCEL_UPLOAD,
      payload: {
        abortControllerId,
        messageId: message.id
      }
    });
  }, [abortControllerId, message.id]);
  const renderMessage = useMemo(() => {
    const isUploading = abortControllerId !== null && abortControllerId !== undefined && isDelivered !== true;
    return <div ref={initRef} className={clsx('relative flex flex-col items-center cursor-pointer text-base')}>
            {isUploading && <div onClick={cancelUpload} className="absolute top-0 z-50 left-0 w-full h-full center-flex flex-col gap-y-2 cursor-pointer transition hover:scale-[1.1]">
                    <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center">
                        <i className="bi bi-x text-xl text-red-600"></i>
                    </div>
                </div>}

            <div className={clsx('flex items-center h-14', received ? 'bg-accent text-primary' : 'bg-primary text-white')}>
                <div className={clsx('w-16 border-r font-semibold flex items-center uppercase justify-center', received ? 'bg-accent text-primary border-gray-200' : 'bg-primary text-white border-gray-600')}>
                    {getFileExtension()}
                </div>
                <div className='text-xs ml-4'>{attachment.metaData.name}</div>
                <button onClick={download} className='w-24 h-24 flex items-center justify-center transition hover:opacity-80'>
                    <i className={clsx('text-xl bi bi-download', received ? 'text-primary' : 'text-accent')}></i>
                </button>
            </div>

            <div className={clsx('absolute attachment-overlay px-2 w-full bottom-0 left-0 z-50 flex items-center justify-end', isUploading && message.uploadingProgress !== undefined && 'justify-between')}>
                {isUploading && message.uploadingProgress !== undefined && <span className='text-white text-2xs'>{message.uploadingProgress}%</span>}
                <div className='flex items-center'>
                    <span className={clsx('text-2xs', received ? 'text-gray-400' : 'text-accent')}>{formattedTimestamp}</span>
                    <MessageDeliveryTicks message={message} />
                </div>
            </div>

            {!received && isUploaded !== true && <div className='absolute w-full h-full flex justify-center items-center px-2 download-overlay pointer-events-none'>
                    <svg fill="#000" className='animate animate-spin w-12 h-12 z-50' viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 1.25c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0c7.318 0.001 13.25 5.933 13.25 13.251 0 3.659-1.483 6.972-3.881 9.37v0c-0.14 0.136-0.227 0.327-0.227 0.537 0 0.414 0.336 0.75 0.75 0.75 0.212 0 0.403-0.088 0.539-0.228l0-0c2.668-2.669 4.318-6.356 4.318-10.428 0-8.146-6.604-14.751-14.75-14.751h-0z"></path>
                    </svg>
                </div>}
            {progress !== null && <div className='absolute w-full h-full flex justify-center items-center px-2 download-overlay'>
                    <span className='text-xs text-accent mr-1'>{progress}%</span>
                    <svg fill="#fff" className='animate animate-spin w-4 h-4' viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 1.25c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0c7.318 0.001 13.25 5.933 13.25 13.251 0 3.659-1.483 6.972-3.881 9.37v0c-0.14 0.136-0.227 0.327-0.227 0.537 0 0.414 0.336 0.75 0.75 0.75 0.212 0 0.403-0.088 0.539-0.228l0-0c2.668-2.669 4.318-6.356 4.318-10.428 0-8.146-6.604-14.751-14.75-14.751h-0z"></path>
                    </svg>
                </div>}
        </div>;
  }, [abortControllerId, attachment.metaData.name, cancelUpload, download, formattedTimestamp, getFileExtension, initRef, isDelivered, message, progress, received, isUploaded]);
  const renderError = useMemo(() => <div className='shadow flex items-center ml-auto my-1 w-fit rounded-sm pl-2 pr-1 py-2 bg-gray-200'>
            <span className='text-base text-gray-600'>Invalid File</span>
            <span className='ml-2 relative -bottom-1 self-end text-gray-400 text-3xs'>{formattedTimestamp}</span>
        </div>, [formattedTimestamp]);
  return renderMessage;
}
export default FileMessage;